import React from 'react';
import { Row, Col, Divider, Radio } from 'antd';
import { util } from '../../utils/Util';
import Moment from 'react-moment';
import { CouponSummary } from './Coupons';

export default class ViewCoupon extends React.Component<{id:string}, {couponType:string, statData:any, data:any}>{   
    constructor(props:any){
        super(props);
        this.state = {couponType:"1",statData:'',data:[]}
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData(){
        fetch(process.env.REACT_APP_REMOTE_URL+'/seller/coupon/'+this.props.id,
        {headers: {'Authorization': util.getToken()}})
        .then(res=>util.handleResponse(res) )
            .then( (data) => 
                this.setState({data:data})
            )
            .catch(()=>{}); 
    }

    render(){
        let data = this.state.data;

        return <div>
                <div className="box">
                <h2>{data.name}</h2>
                <h3 className="red"><CouponSummary data={data} prefix={true} oneLine={true} /></h3>
                <hr />
                <Row>
                    <Col span={8}><label>Quantity</label></Col>
                    <Col span={1}>:</Col>
                    <Col span={15}>{data.totalCount}</Col>
                </Row>
                <Row>
                    <Col span={8}><label>Limit</label></Col>
                    <Col span={1}>:</Col>
                    <Col span={15}>{data.personCount}</Col>
                </Row>
                <Row>
                    <Col span={8}><label>Valid:　</label></Col>
                    <Col span={1}>:</Col>
                    <Col span={15}>
                    {data.timeType==1&&<div><span><Moment format="YYYY.MM.DD">{data.dateFrom}</Moment></span> ~ <span><Moment format="YYYY.MM.DD">{data.dateTo}</Moment></span></div>}
                    {data.timeType==2&&<><span>is valid after receiving in  {data.validDates}days</span></>}
                    </Col>
                </Row>

                <div className="coupon-desc">
                    <h3>Coupon description </h3>
                    <div>
                        {data.description}
                    </div>
                </div>
                </div>
            </div>;
    }
}