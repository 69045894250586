import Request from "./request/index";
import {IUserInfo} from "../view/seller/mine/interface";

const SellerApi = {
    Login:(params:any)=>{
        return Request.POST('/seller/login',params)
    },
    LoginOut:()=>{
        return Request.POST('/seller/logout', {})
    },
    getSellerInfo:()=>{
        return Request.GET('/seller/info',{})
    },
    getCurrentActivity:()=>{
        return Request.GET('/seller/getDoingAct',{})
    },
    getActivityList:(params: { pageNo:number })=>{
        return Request.GET('/seller/getActList',params)
    },
    getActivitySummery:()=>{
        return Request.GET('/seller/getActDataSummary',{})
    },
    getCouponQRInfo:(id:number)=>{
        return Request.GET(`/coupon/${id}`, {})
    },
    getSellerPointsList:(params:{type:string})=>{
        return Request.GET(`/seller/points/list/1`, params)
    },

    updateSellerInfo:(params:any)=>{
        return Request.POST(`/seller/update`, params)
    },
    sendSMS:(params:any)=>{
        return Request.POST('/valicode/sms/send?type=seller',params,"",true)
    },
    forgetPassword:(params:any)=>{
        return Request.POSTJSON('/seller/password/forget',params)
    },
    getCaptcha:(params:{key:string})=>{
        return Request.GET(`/captcha`,params,'',true)
    },
    getMaterialLibiary:(pageNumber:number,params:{pageSize:number,materialType:number})=>{
        return Request.GET(`/seller/materials/list/${pageNumber}`,params)
    },
    deleteMaterial:(params:any)=>{
        return Request.POST(`/seller/deleteMaterials`,params,'',true)
    },
    uploadMaterial:(params:any)=>{
        return Request.POST(`/seller/uploadMaterial`,params,'',true)
    },
    getSellerMaterials:(actId:number)=>{
        return Request.GET(`/customer/getSellerMaterials?activityId=${actId}`,{})
    },
    sendVideo:(params:any)=>{
        return Request.POST(`/customer/coupon/collect`,params,'',true)
    }
}

export default SellerApi;