import React, {useState, useEffect, useRef} from 'react';
import { Cell, FilePicker, Icon,Toast,Badge } from 'zarm';
import SellerApi from "../../../api/Seller";


const UploadMetrial = ()=>{
    const [files, setFiles] = useState([]);

    const onSelect = (selFiles:any) => {
        const newFiles = files.concat(selFiles);
        if (newFiles.length > 5) {
            Toast.show('You can only select up to 5 pictures');
            return;
        }
        console.log(selFiles)
        setFiles(newFiles);
        doUpload();
    };

    const doUpload = ()=>{
        const promiseQuee = files.filter((item:any)=>{
            return SellerApi.deleteMaterial({});
        })
        Promise.all(promiseQuee).then(res=>{

        })
    }

    const remove = (index:number) => {
        const newFiles = [].concat(files);
        newFiles.splice(index, 1);
        setFiles(newFiles);
        Toast.show('删除成功');
    };

    const imgRender = () => {
        return files.map((item:any, index) => {
            return (
                <Badge
                    key={+index}
                    className="file-picker-item"
                    shape="circle"
                    text={
                        <span className="file-picker-closebtn" onClick={() => remove(index)}>
                            <Icon type="wrong" />
                        </span>
                    }>
                    <div className="file-picker-item-img">
                        <a href={item.thumbnail} target="_blank" rel="noopener noreferrer">
                            <img src={item.thumbnail} alt="" />
                        </a>
                    </div>

                </Badge>
            );
        });
    };

    return (
        <div className="uploadMetrialWrap">
            {imgRender()}
            {files.length < 10 && (
                <FilePicker
                    multiple
                    accept="image/*"
                    onChange={onSelect}
                    >
                    <div className="file-picker-btn">
                        <Icon type="add" size="lg" />
                    </div>
                </FilePicker>
            )}
        </div>
    );
}
export default UploadMetrial;
