import React from 'react';
import { Link } from 'react-router-dom';
import './HelpList.css'; // 引入CSS文件

const HelpList = () => {
    const helpDocs = [
        {
            'title': 'Merchant Handbook',
            'url': 'https://proregards-public.oss-accelerate.aliyuncs.com/help/Merchant_Handbook.pdf'
        },
        {
            'title': 'Exhibit A Merchant Guide',
            'url': 'https://proregards-public.oss-accelerate.aliyuncs.com/help/Jun_Exhibit_A_Merchant_Guide.pdf'
        },
        {
            'title': 'Redemption Process',
            'url': 'https://proregards-public.oss-accelerate.aliyuncs.com/help/Redemption_Process.pdf'
        },
        {
            'title': 'Product Explainer Video',
            'url': 'https://www.youtube.com/watch?v=7osw7xicVgk'
        },
        {
            'title': 'Exhibit A Consumer End Procedure',
            'url': 'https://proregards-public.oss-accelerate.aliyuncs.com/help/Exhibit_A_Consumer_End_Procedure.pdf'
        }
    ];

    return (
        <div className="loginWrap">
            <h1>Support Documents</h1>
            <ul>
                {helpDocs.map((doc, index) => (
                    <li key={index} className="help-item">
                        {doc.url.endsWith('.pdf') ? (
                            <>
                                <Link to={`/seller/helpview/${encodeURIComponent(doc.url)}`} className="help-link">
                                    {index + 1}. {doc.title}
                                </Link>
                                <a href={doc.url} download className="download-link">Download</a>
                            </>
                        ) : (
                            <a href={doc.url} target="_blank" rel="noopener noreferrer" className="help-link">
                                {index + 1}. {doc.title}
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default HelpList;
