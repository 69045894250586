import React, {useEffect, useRef, useState} from "react";
import {Switch, Route, NavLink, useHistory, Link} from "react-router-dom";
import Activities from "../view/seller/Activities";
import { CouponRecord, CouponRecordByCouponId } from "../view/seller/CouponRecord";
import Coupons from "../view/seller/Coupons";
import CreateActivity from "../view/seller/CreateActivity";
import { CreateCoupon } from "../view/seller/CreateCoupon";
import Default from "../view/seller/index/Default";
import My from "../view/seller/mine/My";
import Points from "../view/seller/Points";
import UpdateInfo from "../view/seller/mine/UpdateInfo";
import { UseCoupon } from "../view/seller/UseCoupon";
import Login  from '../view/seller/login/Login';
import { ViewActivity } from "../view/seller/index/ViewActivity";
import Scan from "../view/seller/Scan";
import '../view/seller/App.scss';
import  ForgotPassword from "../view/seller/login/ForgotPassword";
import  HelpList from "../view/seller/mine/HelpList";

import ViewCoupon from "../view/seller/ViewCoupon";
import { Col, Row } from "antd";
import ActivityPoster from "../../src/view/seller/ActivityPoster";
import UploadMetrial from "../view/seller/mine/uploadMetrial";
import icon_manual from "../assets/icon/writeoff_manual.png";
import HelpViewer from "../view/seller/mine/HelpViewer";
const SellerRoute = ()=>{

    const activityRef = useRef<any>();
    const backReloadbridge:any = ()=>{
            alert('xx')
            window.history.back();
            window.location.reload()
            setTimeout(()=>{
                // activityRef?.current?.reloaddata();
                alert('reload')
                // window.history.reload()
            },1000)
    };

    const history = useHistory();

    return (
        <Switch>
             <Route path="/seller/login" exact component={Login} />
             <Route path="/seller/forgot-password" exact component={ForgotPassword} />
             <Route path="/seller/help" exact component={HelpList} />
             <Route path="/seller/helpview/:url" component={HelpViewer} />
            <Route path="/seller/activity-create" exact component={CreateActivity} />
             <Route path="/seller/activity-edit/:id" render={route=>{  return <CreateActivity id={`${route.match.params.id}`} history={history}   /> }} />
             <Route path="/seller/activity/view/:id" render={route=><ViewActivity id={route.match.params.id}  />} />
             <Route path="/seller/coupon/create" render={route=><CreateCoupon />} />
             <Route path="/seller/coupon/use/:code" render={route=><UseCoupon code={route.match.params.code} />} />

             <Route path="/seller/points" exact component={Points} />
             <Route path="/seller/my/update" exact component={UpdateInfo} />
             <Route path="/seller/my/uploadmetrial" exact component={UploadMetrial} />

            <Route path="/seller/ActivityPoster/:id" exact render={route=><ActivityPoster id={route.match.params.id}/>} />

            <div>
                <div className="app-main">
                     <Route path="/seller/" exact component={Default} />
                     <Route path="/seller/activities" exact component={Activities} />
                     <Route path="/seller/writeoff" exact component={UseCoupon} />
                     <Route path="/seller/coupons" exact component={Coupons} />
                     <Route path="/seller/activity/record/:id" exact render={route=><CouponRecord id={route.match.params.id} />} />
                     <Route path="/seller/coupon/record/:id" exact render={route=><CouponRecordByCouponId id={route.match.params.id} />} />
                     <Route path="/seller/coupon/view/:id" exact render={route=><ViewCoupon id={route.match.params.id} />} />
                     <Route path="/seller/my" exact component={My} />

                   </div>
                <div className="toolbar-footer">
                       <div className="footer-menu">
                           <div>
                               <NavLink exact to="/seller">
                                   <span className="icon icon-home"></span>
                                   <div>Home</div>
                               </NavLink>
                           </div>
                           <div>
                               <NavLink exact to="/seller/activities">
                                   <span className="icon icon-activity"></span>
                                   <div>Campaigns</div>
                               </NavLink>
                           </div>
                           <div>
                               <NavLink exact to="/seller/writeoff">
                                   <span className="icon use-coupon"></span>
                                   <div>Redeem</div>
                               </NavLink>
                           </div>
                           <div>
                               <NavLink exact to="/seller/coupons">
                                   <span className="icon icon-coupon"></span>
                                   <div>Coupons</div>
                               </NavLink>
                           </div>
                           <div>
                               <NavLink exact to="/seller/my">
                                   <span className="icon icon-my"></span>
                                   <div>Proﬁle</div>
                               </NavLink>
                           </div>
                       </div>
                   </div>
            </div>
       </Switch>);
}

export default SellerRoute;