import { Form, Input, Modal } from 'antd';
import Cookies from 'universal-cookie';
import divisions from './DivisionsCN.json';
//@ts-ignore
import DatePicker from 'react-mobile-datepicker';
import React, {useEffect, useState} from 'react';
import moment from 'moment';
// import en_US from 'antd/es/date-picker/locale/en_US';
import en_US from 'antd/es/locale/en_US';

// import en from "date-fns/locale";
import { FormattedMessage, IntlProvider } from 'react-intl';


export const Format = (props:{number:number})=>{
    let value = props.number;
    if(value === undefined){
        return <span>-</span>;
    }

    if( value == null ){
        value = 0;
    }
    let isW = value>=10000?true:false
    return <span>
        {isW?(value/10000):value} 
        {isW&&<span className="yuan">w</span>}</span>   
}

let messageShown = false;

export const util = {
    getToken: ()=>{
        let cookie = new Cookies();
        let token = cookie.get('Authorization');
        return token;
    },
    isVideo:(path:string) => {
        let arr = path.split('.');
        let suffix = arr[arr.length-1];
        if( ["mp4", "mov"].includes( suffix ) ){
            return true;
        }else{
            return false;
        }
    },
    getVideoDuration: (file:any)=>{
        return new Promise((resolve, reject) => {
            try {
                let video = document.createElement('video')
                video.preload = 'metadata'
        
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    resolve(video.duration);
                }                
                video.src = window.URL.createObjectURL(file)
            } catch (e) {
                reject(e)
            }
        });
    },
    playVideo:(video:any) => {
        if( video.paused ){
            video.play();
        }else{
            video.pause();
        }
    },
    formatDuration:(duration:number)=>{
        duration = Math.floor(duration)
        let minutes = Math.floor(duration/60);
        let seconds:any = Math.floor(duration%60);
        if(seconds < 10){
            seconds = '0'+seconds;
        }
        return minutes+':'+seconds;
    },    
    getProvinces:() =>{
        let result:Array<any> = [];
        for( let child of divisions.children ){
            result.push({name: child.divisionName, value: child.divisionCode});
        }
        return result;
    },
    getCities:(province:string) =>{
        let result =[];
        for(let provinceItem of divisions.children){
            if( provinceItem.divisionCode==province ){
                for( let city of provinceItem.children ){
                    result.push({name: city.divisionName, value: city.divisionCode});
                }
            }
        }
        return result;
    },
    timeFormat: 'YYYY-MM-DD hh:mm',
    getCity: (code:string)=>{
        let result = '';
        for(let provinceItem of divisions.children){
            for( let city of provinceItem.children ){
                if( city.divisionCode == code ){
                    result = city.divisionName;
                }
            }
        }
        return result;
    },
    getCounties:(city:string) =>{
        let result = [];
        for(let provinceItem of divisions.children){
            for( let cityItem of provinceItem.children ){
                if( cityItem.divisionCode == city ){
                    if( cityItem.children ){
                        for(let county of cityItem.children){
                            result.push({name: county.divisionName, value: county.divisionCode});
                        }
                    }
                }                
            }
        }
        return result;
    },
    handleResponse: async (res:Response, onOk?:any): Promise<any>=>{
        if(res.ok){
            let data = await res.json();
            if( data.isSuccess ){
                return new Promise<any>((resolve, reject) => {
                    resolve(data.data);                    
                });
            }else{
                if( !messageShown ){
                    if( data.errroCode=='401' ){
                        Modal.info({
                            icon: null,
                            okText: 'Confirm',
                            onOk: ()=>{
                                let location = window.location.pathname;                                
                                if( location.startsWith('/agency') )
                                {
                                    window.location.href="/agency/login"
                                }else{
                                    let sellerParam = util.getSellerTypeParam();
                                    let params = '';
                                    if( sellerParam ){
                                        params = '?type='+sellerParam;
                                    }
                                    window.location.href="/seller/login"+params;
                                }
                            },
                            content:(<div>{data.errroMsg} <div className="light">Click OK to log in again.</div> </div>)
                        });
                    }else{
                        Modal.info({
                            icon: null,
                            okText: 'Confirm',
                            onOk: ()=>{
                                messageShown = false;
                                if( onOk ){
                                    onOk();
                                }
                            },
                            content:(<p>{data.errroMsg}</p>)
                        });
                    }
                    messageShown = true;
                }
                if(data.errroCode == '3' || data.errroCode == '405') {
                    return new Promise<any>((resolve, reject) => {
                        resolve(data.data);                    
                    });
                } else {
                    return new Promise<any>((resolve, reject) => {
                        reject();                    
                    });
                }
            }
        }else{
            res.json().then((data:any)=>{
                    if( !messageShown ){
                        if( res.status == 401 ){
                            Modal.info({
                                icon: null,
                                okText: 'Confirm',
                                onOk: ()=>{window.location.href="/seller/login"},
                                content:(<div>Login has expired. Click OK to log in again</div>)
                            });
                        }else{
                            Modal.info({
                                icon: null,
                                onOk: ()=>{messageShown = false;},
                                content:data.errroMsg
                            });
                        }
                        messageShown = true;
                    }            
            }).catch(()=>{
                Modal.info({
                    icon: null,
                    onOk: ()=>{messageShown = false;},
                    content:<p>Server Error</p>
                });
                messageShown = true;
            });         
            return new Promise<any>((resolve, reject) => {
                reject();               
            });
        }
    },
    format: (date: Date)=>{
        return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() + " " + date.getMinutes() + ":" + date.getSeconds();
    },
    outputTime:(time:string, dateonly?:boolean)=>{
        if( dateonly ){
            return moment(time).format('YYYY.MM.DD');
        }
        return moment(time).format('YYYY.MM.DD HH:mm');
    },
    isAcitivityTimeOver:(data:any)=>{ //判断活动时间是否结束, 注: 返回false并不意味着正在进行, 需要加状态判断     
        return util.isTimeOver( data.dateTo );
    },

    isCouponExpired:(couponData:any)=>{
        return couponData?.timeType == 1 && util.isTimeOver(couponData.dateTo);
    },
    isTimeOver:(timeStr:any)=>{
        return moment( timeStr ) < moment(); //时间在过去意思是结束了(如活动或卡券)
    },

    getLocationParam: (name:string)=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(name);
    },
    getSellerTypeParam:()=>{
        return util.getLocationParam('type');
    },
    getCookie:(name:string)=>{
        const cookies = new Cookies();
        return cookies.get(name);
    },    
    getSellerType:() => {
        return util.getCookie('stype');
    },
    errorMessage: (data:any) =>{
            Modal.info({
                icon: null,
                okText: 'Confirm',
                content:(<p>{data.errroMsg}</p>)
            });
    },
    message: (message:any) =>{    
            var e = document.createElement('div');
            e.className='fadeout';
            e.innerHTML = '<span>'+message+'</span>';
            setTimeout(() => {
                let elements = document.getElementsByClassName('fadeout');
                for( let i =0; i< elements.length; i++ ){
                    elements[i].remove();
                }
            }, 2000);
            document.body.appendChild(e);
    },
   isExpired:(expireTime:any) =>{
        return moment(expireTime, 'YYYY.MM.DD hh:mm:ss').isBefore(moment())
   },
   range: (size:Number)  =>{
        let result = [];
        for( let i=0;i<size; i++ ){
            result.push(i);
        }
        return result;
   }   
}

export const DateTime =(props:{ value?:Date, placeholder?:string, dateonly?:boolean, onCancel?:any, onSelect?:any,min?:any,max?:any}) => {
    const [isopen, setIsopen] = useState(false);
    let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0);
    now.setDate(now.getDate()+1);
    let currentValue = props.value?moment(props.value).toDate():now;
    const [value, setValue] = useState(props.value?moment(props.value).toDate():'');    

    useEffect(()=>{
        if(props.value) {
            setValue(moment(props.value).toDate());
        }
    },[props.value])

    const dateConfig = {
        'year': {
                    format: 'YYYY',
                    caption: 'Year',
                    step: 1,
                },
        'month': {
                    format: 'MM',
                    caption: 'Month',
                    step: 1,
                },
        'date': {
                    format: 'DD',
                    caption: 'Day',
                    step: 1,
                }};
    const timeConfig = {
        'hour': {
                        format: 'hh',
                        caption: 'Hour',
                        step: 1,
                    },

        'minute': {
                        format: 'mm',
                        caption: 'Minute',
                        step: 10,
                    }};
    const format = props.dateonly?"YYYY-MM-DD":"YYYY-MM-DD HH:mm";                    
    const datetimeConfig= props.dateonly?dateConfig:{...dateConfig, ...timeConfig};
    const onSelect = ()=>{
        setIsopen(false);
        setValue( currentValue );
        props.onSelect( moment(currentValue).format(format) );
    }

    const messages = {
        cancel: 'Cancel',
        done: 'Done',
    };

// <IntlProvider locale="en" messages={messages}> {/* 设置使用英文 */}
    return <div className="date-picker">
            <DatePicker dateConfig={datetimeConfig}
                    theme="ios" 
                    headerFormat={props.dateonly?"YYYY-MM-DD":"YYYY-MM-DD hh:mm"}
                    min={props.min}
                    max={props.max}
                    showCaption={true} 
                    onChange={(v:any)=>currentValue=v}
                                                    // locale={en_US}

                    value={value?value:now}
                        confirmText={'Confirm'}
                        cancelText={'Cancel'}
                    locale={{
                        year: '年',
                        month: '月',
                        day: '日',
                        hour: '时',
                        minute: '分',
                        am: '上午',
                        pm: '下午',
                    }}
                    onCancel={()=>setIsopen(false)} onSelect={()=>onSelect()} isOpen={isopen} />
                    <Input readOnly={true} placeholder={props.placeholder} value={value?moment(value).format(format):''} onClick={()=>setIsopen(true)} />
            {/*<FormattedMessage id="cancel" /> /!* 使用FormattedMessage来显示取消按钮文本 *!/*/}
            {/*<FormattedMessage id="done" /> /!* 使用FormattedMessage来显示完成按钮文本 *!/*/}

        </div>
    // </IntlProvider>
}

export const Time =(props:{ value?:Date, placeholder?:string, onCancel?:any, onSelect?:any}) => {
    const [isopen, setIsopen] = useState(false);
    let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0);;

    const createTime = (time:any)=>{
        return moment( moment().format("YYYY-MM-DD")+" "+time).toDate();
    }

    let currentValue = props.value?createTime(props.value):now;
    const [value, setValue] = useState(props.value?currentValue:'');    
   
    const timeConfig = { 'hour': {
                        format: 'hh',
                        caption: 'Hour',
                        step: 1,
                    },
                    'minute': {
                        format: 'mm',
                        caption: 'Minute',
                        step: 5,
                    }};
    const format = "HH:mm";                 

    const onSelect = ()=>{
        setIsopen(false);
        setValue( currentValue );
        props.onSelect( moment(currentValue).format(format) );
    }

    return <div className="time-picker"><DatePicker dateConfig={timeConfig} 
                    theme="ios" 
                    headerFormat={"hh:mm"}
                    showCaption={true} 
                    onChange={(v:any)=>currentValue=v}                
                    value={value?value:now} 
                    onCancel={()=>setIsopen(false)} onSelect={()=>onSelect()} isOpen={isopen} />
                    <Input readOnly={true} placeholder={props.placeholder} value={value?moment(value).format(format):''} onClick={()=>setIsopen(true)} />
    </div>
}

export const FadeOut = (props:{message:string})=>{
    const [shown, setShown] = useState(true);

    setTimeout(() => {
        setShown(false);
    }, 5000);

    return <>{shown&&<div className="fadeout">{props.message}</div>}</>;
}

export const PopupBottom = (props:{shown?:boolean, content:any, footer?:any, children: React.ReactNode})=>{
    const [shown, setShown] = useState(props.shown?true:false as boolean);

    return <>{shown&&<div className="popup-bottom-mask">
                <div className="popup-bottom">
                    {props.content}
                    {props.footer&&<div onClick={()=>setShown(false)} className="popup-cancel">
                        {props.footer}
                    </div>}
                </div>
            </div>}
            <div onClick={()=>setShown(true)}>
                {props.children}
            </div>
            </>;
}

let videoRule:any = '';

export const CalcVideoCount = async (actType: any, videoCount:number)=>{
            const calc = ()=>{
                let limit = videoRule[parseInt(actType)];
                let maxConfig = limit.videoMaxConfig;
                let result = '';
                if( maxConfig[videoCount] ){
                    result = maxConfig[videoCount];
                }
                return result;
            }


            if( videoRule ){
                return calc();
            }else{
                let res = await fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getFileLimitRule",
                {headers:{Authorization: util.getToken()}})
                let data = await util.handleResponse(res);
                videoRule = data;
                return calc();
            }                     
}