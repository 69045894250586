import React from 'react';
import {Alert, Button, Col, List, Menu, Modal, Radio, Row, Space, Table} from 'antd';
import { Switch, BrowserRouter as Router, Route, Link, useLocation, Redirect } from "react-router-dom";
import { GlobalOutlined, LoadingOutlined } from '@ant-design/icons';
import Cookies from 'universal-cookie';
import { util } from '../../utils/Util';
import { CouponSummary } from './Coupons';
import InfiniteScroll from 'react-infinite-scroll-component';

//通过activityId取得列表
export class CouponRecord extends React.Component<{id:string}, {tab:string, stat: any, data:any, loadedPage:number}>{       
    constructor(props:any){
        super(props);
        this.state = {tab:'0', stat:'', data:'', loadedPage: 1}
    }

    componentDidUpdate(prevProps:any, prevState:any){
        if( prevState.tab != this.state.tab ){
            this.fetchData();
        }
    }

    componentDidMount(){
        this.fetchStat();
        this.fetchData();
      };


    fetchStat(){
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/coupon/record/statCountByActivityId/"+this.props.id,
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res))
            .then(data=>this.setState({stat:data}))
            .catch(()=>{});        
    }

    fetchData(page?:number){   
        let type = '';     
        switch(this.state.tab){
            case '1':
                type = '&status=1';
                break;
            case '2':
                type = '&status=0';
                break;
            case '3':
                type = '&status=2';
                break;
        }
        let loadPage = page?page:this.state.loadedPage;
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/coupon/record/list/"+loadPage+"?activityId="+this.props.id+type,
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res))
            .then(data=>{
                if( page ){
                    if( data.length ==0 ){
                        this.setState({loadedPage: 0});
                    }else{
                        this.setState({data:[...this.state.data, ...data], loadedPage: page});
                    }
                }else{
                    this.setState({data:data}); 
                }
            })
            .catch(()=>{});
    }   

    loadMore(){
        this.fetchData(this.state.loadedPage+1);
    }

    render(){       
        if(!this.state.data){
            return '';
        }
        return <div className="space-container main-area" id="main">
            <div className="center coupon-tab">
            <Radio.Group defaultValue="0" buttonStyle="solid" onChange={e=>this.setState({tab: e.target.value,loadedPage: 1})}>
                <Radio.Button value="0">
                    Received:
                    <h3>{this.state.stat.all}</h3>
                </Radio.Button>
                <Radio.Button value="1">
                    Redeemed
                    <h3>{this.state.stat.used}</h3></Radio.Button>
                <Radio.Button value="2">Unredeemed
                <h3>{this.state.stat.unUsed}</h3></Radio.Button>
                {/*<Radio.Button value="3">expired*/}
                {/*<h3>{this.state.stat.expired}</h3></Radio.Button>                */}
            </Radio.Group>    
            </div>
            {this.state.data.length>0&&<div className="box">
            <InfiniteScroll
                        scrollableTarget="main"
                        dataLength={this.state.data.length}
                        next={()=>this.loadMore()}
                        hasMore={this.state.data.length>=10}
                        loader={this.state.loadedPage!=0?<LoadingOutlined />:''}
                        >
                {this.state.data.map((item:any, index:number)=><div className="coupon-record">
                    <RenderRecord data={item} />
                    {index<this.state.data.length-1&&<hr />}
                </div>)}
                </InfiniteScroll>
            </div>}
            {this.state.data.length==0&&<Alert message="no coupon records" />}
        </div>;
    }

}

//通过couponId取得列表
export class CouponRecordByCouponId extends React.Component<{id:string}, {tab:string, loadedPage:number, data:any}>{   
    constructor(props:any){
        super(props);
        this.state = {tab:'0', loadedPage:1, data:''}
    }

    componentDidMount(){
        this.fetchData();
      };

    componentDidUpdate(prevProps:any, prevState:any){
        if( prevState.tab != this.state.tab ){
            this.fetchData();
        }
    }

    fetchData(page?:number){   
        let type = '';     
        switch(this.state.tab){
            case '1':
                type = '&status=1';
                break;
            case '2':
                type = '&status=0';
                break;
            case '3':
                type = '&status=2';
                break;
        }
        let loadPage = page?page:this.state.loadedPage;
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/coupon/record/list/"+loadPage+"?couponId="+this.props.id+type,
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res))
            .then(data=>{
                if( page ){
                    if( data.length ==0 ){
                        this.setState({loadedPage: 0});
                    }else{
                        this.setState({data:[...this.state.data, ...data], loadedPage: page});
                    }
                }else{
                    this.setState({data:data}); 
                }
            })
            .catch(()=>{});
    }   


    loadMore(){
        this.fetchData(this.state.loadedPage+1);
    }


    render(){       
        if(!this.state.data){
            return '';
        }
        return <div className="space-container main-area" id="main">
            <div className="center">
            <Radio.Group defaultValue="0" buttonStyle="solid"  onChange={e=>this.setState({tab: e.target.value, loadedPage:1})}>
                <Radio.Button  value="0">received</Radio.Button>
                <Radio.Button value="1">Redeemed</Radio.Button>
                <Radio.Button value="2">Unredeemed</Radio.Button>
                {/*<Radio.Button value="3">expired</Radio.Button>*/}
            </Radio.Group>    
            </div>
            {this.state.data.length>0&&<div className="box">
            <InfiniteScroll
                        scrollableTarget="main"
                        dataLength={this.state.data.length}
                        next={()=>this.loadMore()}
                        hasMore={this.state.data.length>=10}
                        loader={this.state.loadedPage!=0?<LoadingOutlined />:''}
                        >
                {this.state.data.map((item:any, index:number)=><div className="coupon-record">
                    <RenderRecord data={item} />
                    {index<this.state.data.length-1&&<hr />}
                </div>)}
                </InfiniteScroll>
            </div>}
            {this.state.data.length==0&&<Alert message="no coupon records" />}
        </div>;
    }

}

const writeOff = (code:string)=>{

    Modal.confirm({
        onOk: ()=>{
            fetch(process.env.REACT_APP_REMOTE_URL+'/seller/coupon/record/writeoff/'+code,{
                headers: {'Authorization': util.getToken()},
                method:'POST'
            }).then((res:any)=>util.handleResponse(res)).then((data:any)=>{
                Modal.info({
                    content: <p>Redeemed</p>,
                    onOk: ()=>{window.location.href = window.location.href;}}
                );
            }).catch(()=>{});
        },
        cancelText:'Cancel',
        okText:'Confirm',
        content:<p>Redeem</p>});
}




const RenderRecord = (props: {data:any}) =>{
    return <>
            <div className="right">
                {props.data.status==0?'Unredeemed':''}
                {props.data.status==1?'Redeemed':''}
                {props.data.status==2?'Expired':''}
            </div>
                <CouponSummary oneLine={true} data={props.data.coupon} prefix={true} />
            <div>
                <label>Customer: </label> {props.data.customer.name}
            </div>
            <div>
                <label>Received time: </label> {props.data.createdTime}
            </div>

        {props.data.status==0?<div className="right"><Button size="small"  className="tiny" onClick={(e)=>{ e.preventDefault();writeOff(props.data.code);}}>Confirm Redeem</Button></div>:''}
        </>;
}