import Request from "./request/index";

const AgentApi = {
    Login:(params:any)=>{
        // agent to be implemented
        return {}
    },
    // LoginOut:()=>{
    //     return Request.POST('/seller/logout', {})
    // },
    getAgentInfo:()=>{
        // agent to be implemented
        return {}
    },
    sendSMS:(params:any)=>{
        return Request.POST('/valicode/sms/send?type=agent',params,"",true)
    },
    forgetPassword:(params:any)=>{
        return Request.POSTJSON('/agent/password/forget',params)
    },
    valicode:(params:any)=>{
        return Request.POSTJSON('/valicode/sms/verify',params)
    },


    // getCurrentActivity:()=>{
    //     return Request.GET('/seller/getDoingAct',{})
    // },
    // getActivityList:(params: { pageNo:number })=>{
    //     return Request.GET('/seller/getActList',params)
    // },
    // getActivitySummery:()=>{
    //     return Request.GET('/seller/getActDataSummary',{})
    // },
    // getCouponQRInfo:(id:number)=>{
    //     return Request.GET(`/coupon/${id}`, {})
    // },
    // getSellerPointsList:(params:{type:string})=>{
    //     return Request.GET(`/seller/points/list/1`, params)
    // },
    // bindDouYinQRcode:()=>{
    //     return Request.GET(`/seller/bind/douyin/qrcode`, {})
    // },
    // updateSellerInfo:(params:any)=>{
    //     return Request.POST(`/seller/update`, params)
    // }

}

export default AgentApi;