import React from 'react';
import { SegmentedControl, WingBlank } from 'antd-mobile';
import { MyInfo, MyPoints } from './mine/My';
import SellerApi from "../../api/Seller";
import Placeholder from "../components/placeholder/placeholder";
import recordsNull from "../../assets/img/records-null.png";

export default class Points extends React.Component<{}, {tab:number, data:any}>{
    constructor(props:any){
        super(props);
        this.state = {
            tab:2,
            data:''
        }
    }

    componentDidMount(){
        this.fetchData();
      };

    componentDidUpdate(prevProps:any, prevState:any){
        if( prevState.tab != this.state.tab ){
            this.fetchData();
        }
    }

    fetchData(){
        SellerApi.getSellerPointsList({type:this.state.tab.toString()}).then(data=>{
            this.setState({ data:data });
        });
    }
    onValueChange = (value:string) => {
        console.log(value);
    }
    //分段器切换
    onSegmentChange = (e:any) => {
        if(e.nativeEvent.selectedSegmentIndex===0){
            this.setState({tab: 2})
        }else {
            this.setState({tab: 1})
        }
    }

    render(){       

        return (
            <div className="points">
                <div className="points-header">
                    <MyInfo render={(info:any)=><>                
                       <MyPoints data={info} />
                    </>} />                    
                </div>
                <div>
                    {/*<span className="right"><a href="http://vshop.shxmao.com/mb/shop/index?sid=4032357">去充值</a></span>*/}
                    <h2>
                        <span className="h2-strip"></span>
                        Record of video point usage
                    </h2>
                </div>
                {this.state.data&&<div className="box" style={{padding:"20px 12px 26px"}}>
                    <WingBlank size="lg" style={{paddingBottom:"13px"}} className="box-row-m-m">
                        <SegmentedControl
                            values={['Reduce', 'Recharge']}
                            tintColor={'#ff1e1e'}
                            style={{width: '200px', height: '36px'}}
                            selectedIndex={this.state.tab==2?0:1}
                            onChange={this.onSegmentChange}
                        />
                    </WingBlank>

                    {this.state.data.length>0&&<div className="xiangdouItemBox">
                        {this.state.data.map((item:any, index:any)=> <div className="box-row-start-m" key={index}>

                                    <div className="box-row-between-m box-flex-def xiangdouItemDetail text-xs">
                                        <div>
                                            <div className="box-row-start-m">
                                                <span>{item.type==2?item.actName:item.remark}</span>
                                            </div>
                                            <div className="light text-xs">{item.createdTime}</div>
                                        </div>

                                        {item.type==2&&<div className="text-l text-danger box-row-start-m" ><span className="xiangDouItemIcon"></span>{item.pointsChanged}</div>}
                                        {item.type==1&&<div className="text-l text-success box-row-start-m" ><span className="xiangDouItemIcon">+</span>{item.pointsChanged}</div>}
                                    </div>

                                </div>)}
                    </div>}
                    {this.state.data.length==0&&<div className="box-row-m-m placeholderWrap"  style={{width:"100%",height:"500px"}}>
                        <Placeholder
                            src={<img src={recordsNull} alt="" style={{width:"149px",height:"114px"}}/>}
                            title=""
                            message={<div>{this.state.tab==2?"No records":"No records"}</div>}
                            button={<div></div>}></Placeholder>
                    </div>}
                </div>}
        </div>);
    }

}