import React from 'react';
import Cookies from 'universal-cookie';
import { util } from '../../../utils/Util';
import lockIcon from '../../../assets/icon/lock.png';
import sellerInfoIcon from '../../../assets/icon/sellerInfoIcon.png';
import materialIcon from '../../../assets/icon/materialIcon.png';
import userAvatar from '../../../assets/icon/seller-avatar.jpg';
import arrowRightGrayIcon from "../../../assets/svg/arrow_right_gray.svg";
import { List, Button, ActionSheet,Toast } from 'antd-mobile';
import "./mine.scss";
import SellerApi from "../../../api/Seller";
import XMListItem from "./ListItem";
import {IUserInfo} from "./interface";


const Item = List.Item;

export default class My extends React.Component<any, {data:any,disabled:boolean,clicked:string,isAllComplete:boolean}>{
    constructor(props:any){
        super(props);
        this.state = {
            data:'',
            disabled: false,
            clicked: 'none',
            isAllComplete:false,
        }
    }

    componentDidMount(){
        this.fetchData();
    };

    fetchData(){
        SellerApi.getSellerInfo().then((res:any)=>{
            const data:IUserInfo = res
            const complete:boolean = (data?.name&&data?.realName&&data?.province&&data?.city&&data?.county&&data?.address&&data?.phone&&data?.businessLicenseUrl&&data?.douyinId&&data?.logo)?true:false;
            this.setState({
                isAllComplete:complete,
                data: res
            });
        });
    }

    loginOut(){
        // e.preventDefault();
        SellerApi.LoginOut().then(res=>{
            const cookie = new Cookies();
            cookie.remove('Authorization', {path: '/seller', sameSite: true});
            window.location.href="/seller/login";
        });
    }

    showActionSheet = () => {
        const BUTTONS = ['Log Out', 'Cancel'];
        ActionSheet.showActionSheetWithOptions({
                options: BUTTONS,
                cancelButtonIndex: BUTTONS.length - 1,
                message: '',
                maskClosable: true
            },
            (buttonIndex) => {
                console.log(buttonIndex)
                if(buttonIndex==0){
                    this.loginOut();
                }else{
                    this.setState({ clicked: BUTTONS[buttonIndex] });
                }
            });
    }


    render(){       
        let info = this.state.data;

        if( !info ){
            return '';
        }
        const getExtra = (content:string,hit:string)=>{
            if(content){
                return content
            }else{
                return <div className="text-danger">{hit}</div>
            }
        }

        const { isAllComplete } = this.state;

        return (
           <div className="mineBox relative" >
               <div className="mine-info-box">
                   <div className="box-row-between-m seller-info-item-box">
                       <div>
                           {info.logo&&info.logo!=''?<div className="seller-logo" style={{backgroundImage:"url('"+info.logo+"')"}}></div>:<div className="seller-logo" style={{backgroundImage:"url('"+userAvatar+"')"}}></div>}
                       </div>
                       <div className="box-column-m-start box-flex-def">
                           <div className="box-row-between-base">
                               <h2 className="seller-name text-ellipsis">{this.state.data.name}</h2>
                           </div>
                           {!this.props.isParentSeller&&<div>Valid until ：{info.expired?info.expired.split(' ')[0]:''}</div>}
                       </div>
                   </div>

                   {!this.props.isParentSeller &&<div style={{padding:"0 12px"}}><MyPoints data={info}/></div> }
               </div>
               <div style={{overflow:'scroll',margin:'0 12px',borderRadius:"6px"}} >
                   <div className='listContentWrap' onClick={()=>{ window.location.href="/seller/my/update" }}>
                       <XMListItem
                           icon={<img src={sellerInfoIcon}/>}
                           title='Merchant Information'
                           content={<div className="text-danger"><span style={{marginRight:"4px"}}>{isAllComplete?"":"Edit"}</span><img src={arrowRightGrayIcon} alt="Right Arrow" width={6} height={10} /></div>}/>
                   </div>

                   <div className='listContentWrap' onClick={()=>{this.props.history.push("/seller/forgot-password?from=change")}} >
                       <XMListItem icon={<img src={lockIcon}/>} title='Change Password' content={ <img src={arrowRightGrayIcon} alt="Right Arrow" width={6} height={10}/> }/>
                   </div>

                   <div className='listContentWrap' onClick={()=>{this.props.history.push("/seller/help")}} >
                       <XMListItem icon={<img src={lockIcon}/>} title='Support' content={ <img src={arrowRightGrayIcon} alt="Right Arrow" width={6} height={10}/> }/>
                   </div>

                   <div style={{width:"100%",padding:"0 12px",position:"absolute",bottom:"100px",left:"0"}} className="box-row-m-m">
                       <Button onClick={this.showActionSheet} style={{ fontSize:"16px",color:"blue",flex:1 }} >Log Out </Button>
                   </div>
               </div>

           </div>
        );
    }
}

export class MyInfo extends React.Component<{render:any}, {data:any}>{       
    constructor(props:any){
        super(props);
        this.state = {data:''}
    }

    componentDidMount(){
        this.fetchData();
      };

    fetchData(){
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/info",
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then((data:any)=>this.setState({data:data}))
            .catch(()=>{});
    }   

    render(){           
        return this.props.render(this.state.data);
    }

}

export const MyPoints = (props:{data:any})=>{
    return(
        <div className="box my-points-header box-row-between-m">
            <div className="text-xs" style={{width:"40%",margin:0}}>
                <div>Total Video  Points</div>
                <div className="text-bold xiangdouNum text-normal" style={{marginTop:"5px"}}>{props.data.totalPoints>0?props.data.totalPoints:0}</div>
            </div>
            <div className="text-xs" style={{width:"40%",margin:0}}>
                {/*<div style={{width:"1px",height:"40px",margin:"0 39px 0 15px",backgroundColor:"rgba(255, 255, 255, 0.2)"}} />*/}
                {/*<div className="box-column-m-m">*/}
                    <div>Available Video  Points </div>
                    <div className="text-bold xiangdouNum text-normal" style={{marginTop:"5px"}}>{props?.data?.points>0?props.data.points:0}</div>
                {/*</div>*/}
            </div>
            <div style={{width:"20%"}}>

                    <Button className="fillin-points box-row-m-m text-xs text-bold" style={{boxShadow: "0px 2px 4px rgba(213, 28, 10, 1)"}}
                            onClick={() => {Toast.info('Please contact your service manager');} }>Recharge</Button>

            </div>
        </div>
    )

}