import React from 'react';
import { Modal } from 'antd';
import Cookies from 'universal-cookie';
import SellerApi  from '../../../api/Seller';
import { useState } from 'react';
import { ConfigProvider,Input as ZAInput, Cell, Icon,Button as ZAButton,Radio as ZARadio } from 'zarm';
import kuaiShouRoundIcon from "../../../assets/icon/kuaishou_round.png";
import douYinRoundIcon from "../../../assets/icon/douyin_round.png";
import iconMobile from "../../../assets/svg/mobile.svg";
import iconPassword from "../../../assets/svg/password.svg";
const enUS = require('zarm/lib/config-provider/locale/en_US').default;


const Login =()=>{
    const [type, setType] = useState<string>('tiktok');
    const [phone, setPhone] = useState<any>('');
    const [password, setPassword] = useState<any>('');

    const submit=async()=>{
        try {
            const loginRes:any =await SellerApi.Login({ type:type,phone:phone,password:password });
            if(loginRes) {
                let token = loginRes.token;
                const cookies = new Cookies();
                const date = new Date();
                date.setDate(date.getDate() + 15);
                cookies.set('Authorization', token, { expires: date, path: '/seller', sameSite: true });
                window.location.href = '/seller?v=' + new Date().getTime();

            }
        }catch (err){
            console.log(err)
        }
    };

        return (
            <div className="loginWrap">
                <h1 className="text-xxl text-def text-bold ">Hi，</h1>
                <h2 className="text-l text-def">Welcome to CustomerFunnel</h2>
                <div className="loginIptWrap">
                    <Cell icon={<img alt="" src={iconMobile} style={{ width: 24, height: 24 }} />}>
                        <ConfigProvider locale={enUS}>
                        <ZAInput
                            clearable
                            type="number"
                            placeholder="Please enter your phone number"
                            value={phone}
                            onChange={(value:any) => { setPhone(value); }}/>
                        </ConfigProvider>
                    </Cell>
                    <Cell icon={<img alt="" src={iconPassword} style={{ width: 24, height: 24 }} />}>
                        <ZAInput
                            clearable
                            type="password"
                            placeholder="Please input a password"
                            value={password}
                            onChange={(value:any) => { setPassword(value); }}/>
                    </Cell>
                </div>
                <ZAButton block shape="radius" disabled={phone==''||password==''} className="loginBtn text-normal" onClick={()=>submit()}>Login</ZAButton>
                <div className="box-row-m-start" style={{margin:"20px"}}>
                    <ZAButton href="/seller/forgot-password" className="text-s" style={{fontSize:"14px",lineHeight:"22px",color:"#ff1e1e",marginRight:'-6px'}}>Forget Password</ZAButton>
                 </div>
            </div>
        );
    }

export default Login;
