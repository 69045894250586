import React, { useEffect, useState } from 'react';
import {Button, Form, Input, Radio, Modal, Progress, Row, Col, Card} from 'antd';
import { Steps,Toast } from 'antd-mobile';
import { Coupon } from './Coupons';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { DateTime, util } from '../../utils/Util';
import axios from 'axios';
import moment from 'moment';

import iconBack from "../../assets/icon/icon-back.png";
import iconSave from "../../assets/icon/icon-save.png";
 import Request, { EHostType } from "../../utils/Request";


const { Step } = Steps;
const { TextArea } = Input;

const steps = ["Basic configuration", "Upload videos", "Coupon configuration" ];

export default class CreateActivity extends React.Component<{id?:string,history:any},
    { id:number, loading:boolean, currentStep: number,  dateFrom:any, dateTo:any,
        publishFrom:any, fileLimit:any, myPoints:number, actType:any,
        videoPublishType:string, saving:boolean, uploadProgress:number,fileToUploadNum:number,fileUploadedNum:number,
        fileUploadCancelled:boolean,
        materialList:Array<any>, coupons:any, data:any, validation:any,
        videoDescriptionCount:Array<number>, couponType:number,
        videoTopic:any,key:any,expired:any}>{
    formRef: any;
    uploadCancelSource:any;

    constructor(props: any) {
        super(props);
        this.formRef = React.createRef();
        // @ts-ignore
        // @ts-ignore
        this.state = {
            id: props.id?props.id:0,
            loading:false,
            currentStep: 0,
            dateFrom:'',
            dateTo:'',
            publishFrom:'',
            saving:false,
            myPoints: 0,
            fileLimit:'',
            actType:2,
            validation:{},
            uploadProgress: 0,
            fileToUploadNum:0,
            fileUploadedNum:0,
            fileUploadCancelled:false,
            videoPublishType:"1",
            coupons:[],
            data:{},
            materialList:[],
            couponType: 0,
            videoDescriptionCount:[0],//[0,1,-1,3], -1指无效, 其它表示videoDescriptionList的值. 用于添加/删除视频解说
            videoTopic:[],
            key:props.key,
            expired:'',
        };
    }

    componentDidMount(){
        if( this.props.id ){
            this.fetchExisting(this.props.id);
        }else{
            let from = util.getLocationParam('from');
            if( from ){
                this.copyFrom(from);
            }
        }
        this.setState({
            dateFrom:moment().format("YYYY-MM-DD"),//活动开始时间默认当前时间
            dateTo:moment().add(30,'days').format("YYYY-MM-DD"),//活动结束时间默认当前时间+30天
        })

        this.fetchCoupons();
        this.fetchMyInfo();

        window.onpageshow = function(event:any){
            if (event.persisted) {
                window.location.reload();
            }
        }
    };
    componentWillUnmount (){
        localStorage.removeItem('materialInfo')
    }

    //用户信息
    fetchMyInfo(){
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/info",
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then((data:any)=> {
                this.setState({
                    myPoints: data.points,
                    // actType: data.points>1?'1':'0',
                    expired: data.expired
                });

                let from = util.getLocationParam('from');
                // this.formRef.current.setFieldsValue({maxPoints:data.points<1?'':data.points})

            }).catch(()=>{});
    }



    //活动详情
    fetchExisting(id:string){
        console.log('fetchExisting..');
        this.setState({loading:true});
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getActById?id="+id,
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then((data:any)=>{
                if( data ){
                    //debugger;
                    let material:any = []
                    //debugger;
                    if(data.materialList ){
                        let array=data.materialList.split(";");
                        array.map((item: any)=>{
                            // @ts-ignore
                            material.push(item);
                        }) ;
                    }
                    //this.materialData=material;
                    console.log('material:'+   material);
                    // const materialInfo:any =   localStorage.getItem('materialInfo');
                    // if(materialInfo){
                    //     const materialObject = JSON.parse(materialInfo);
                    //     const baseMaterial = material.filter((item:any)=>{ return  item.id===0 }) //老数兼容
                    //     material = [...materialObject.info,...baseMaterial];
                    //     this.setState({ currentStep:1 });
                    // }
                    this.setState({
                        data:data,
                        loading:false,
                        // actType: data.actType.toString(),
                        dateFrom: data.dateFrom,
                        dateTo:data.dateTo,
                        videoDescriptionCount:util.range(data.videoDescriptionList.length),
                        publishFrom: data.publishFrom,
                        materialList: material,
                        videoTopic: this.convertTopics(data.topicList)
                    },()=>{
                        localStorage.removeItem('materialInfo')
                    });
                }
            }).catch(()=>{});
    }
    //复制活动
    copyFrom(id:string){
        this.setState({loading:true});
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getActById?id="+id,
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then((data:any)=>{
                if( data ){
                    data['id'] = 0;
                    // data['maxPoints']='';
                    this.setState({
                        data:data,
                        loading: false,
                        // actType: data.actType.toString(),
                        videoDescriptionCount:util.range(data.videoDescriptionList.length),
                        videoPublishType: data['videoPublishType'].toString()
                    });
                }
            }).catch(()=>{});
    }

    //优惠券
    fetchCoupons(){
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/coupon/getCouponListBySeller",
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then( (data:any) =>
                this.setState({coupons:data})
            ).catch(()=>{});
    }

    previousStep() {
        this.setState({ currentStep: this.state.currentStep - 1 });
    }

    //save draft
    saveDraft(){
        let values = this.formRef.current.getFieldsValue(true);
        this.save(values, false);
    }

    //next/submit
    submit(values:any){
        let currentStep = this.state.currentStep;
        let validation:any = {};

        if( currentStep == 0 ){
            if( !this.state.dateFrom ){
                util.message('Please enter the campaign start time');
                return;
            }

            if( !this.state.dateTo ){
                util.message('Please enter the campaign end time');
                return;
            }

            // if( !moment(this.state.dateFrom,'YYYY-MM-DD').isAfter(moment(new Date(),'YYYY-MM-DD'),"days")&&!moment(this.state.dateFrom,'YYYY-MM-DD').isSame(moment(new Date(),'YYYY-MM-DD'),"days")){
            //     util.message('The start time cannot be in the past' );
            //     return;
            // }

            if( !moment(this.state.dateTo).isAfter( this.state.dateFrom )&&(this.state.dateTo!=this.state.dateFrom) ){
                util.message('The end time must be later than the start time');
                return;
            }

            let idParam = '';
            if( this.state.id ){
                idParam = '&ignoreActivityId='+this.state.id;
            }

            // if(this.state.actType!='1'){
            //     this.setState({materialList:this.state.materialList.filter(item=>item.type==1)})
            // }

            this.setState({ currentStep: currentStep + 1 });
        } else if( currentStep == 1 ) {

            this.setState({ currentStep: currentStep + 1 });
            if(this.state.coupons.length>0){
                setTimeout(()=>{
                    this.formRef.current.setFieldsValue({couponIdList:this.state.coupons[0]?.id+""})
                },500)

            }
        }else if( currentStep == 2 ){
            this.save(values, true );
        }

    }

    save(values:any, commit:boolean,path?:string){

        let videoListStr =this.state.materialList.join(';');

        values['materialsListStr'] = videoListStr;

        let videoDescriptionList = values['videoDescriptionList'];
        if( videoDescriptionList ){
            let videoDescriptionValues = [];
            for( let index of this.state.videoDescriptionCount ){
                if(index!=-1){
                    videoDescriptionValues.push( videoDescriptionList[index] );
                }
            }
            values['videoDescriptionList'] = videoDescriptionValues;
        }

        const formData = new FormData();
        if( this.state.id ){
            formData.append('id', this.state.id.toString());
        }
        //debugger;
        if(this.state.dateFrom){
            const fromDateAddition =this.state.dateFrom.split(' ').length==2? this.state.dateFrom: `${this.state.dateFrom} 00:00`;
            formData.append('dateFrom', fromDateAddition);
        }

        if(this.state.dateTo){
            const toDateAddition = this.state.dateTo.split(' ').length==2? this.state.dateTo: `${this.state.dateTo} 23:59`;
            formData.append('dateTo', toDateAddition);
        }

        if(this.state.publishFrom){
            formData.append('publishFrom', this.state.publishFrom);
        }

        let videoTopicStr = '';
        let videoTopicArr = [];
        for( let item of this.state.videoTopic ){
            videoTopicArr.push( item.id );
        }
        videoTopicStr = videoTopicArr.join(',');
        formData.append('videoTopic', videoTopicStr);

        for(let key in values){
            if( values[key]!=undefined ){
                formData.append(key, values[key]);
            }
        }

        if(commit){
            formData.append('commit', 'true');
        }
        this.setState({saving:true,});

        if(formData.get('videoPublishType')==='1'){
            formData.delete('publishFrom')
            formData.delete('publishInterval')
        }else if(formData.get('videoPublishType')==='2'){
            formData.delete('publishInterval')
        }else if(formData.get('videoPublishType')==='3'){
            formData.delete('publishFrom')
        }

        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/addOrUpdateActivityForInit",
            {method: 'POST',
                headers:{Authorization: util.getToken()},
                body: formData} )
            .then(res=>util.handleResponse(res) )
            .then( (data:any)=>{
                if(commit){
                    Modal.info({
                        icon: null,
                        okText: 'Return to Campaign',
                        onOk: ()=>{window.location.href = '/seller/activities';},
                        content: <div>
                            <p>
                                Successfully created campaign! Campaign is under review!
                            </p>
                        </div>
                    });
                }else{
                    this.setState({id: data.id});

                    if(path){
                        this.props.history.replace(`${path}&id=${data.id}`);
                        return;
                    }
                    if(commit){
                        Modal.info({
                            icon: null,
                            content:<p>Saved successfully!</p>,
                            okText: 'Go Back',
                            onOk:()=>{window.location.href='/seller/activities'}
                        });
                    }else{
                        Toast.info("Saved successfully");
                    }
                }
            }).catch(()=>{}).finally(()=>this.setState({saving:false}));
    }




    fileTotalNumToUpload=0;

    async uploadSingleFiles(file:any){

        if( file.size > 100*1024*1024 ){
            Modal.error({
                content:(<p>Cannot exceed 100M</p>)
            });
            return;
        }

        if( this.state.materialList.length > 500 ){
            Modal.error({
                content:(<p>The number of videos cannot exceed 500</p>)
            });
            return;
        }
        const formData = new FormData();
        formData.append('file', file);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.uploadCancelSource = source;

        let options = {
            headers: {Authorization: util.getToken()},
            cancelToken: source.token,
            timeout: 5*1000,
            // onUploadProgress: (event:any) => {
            //     const { loaded, total } = event;
            //     let percentage = loaded/total*100;
            //     if( percentage >= 99 ){
            //         percentage = 99; //to 99 and waiting for response to set 100
            //     }
            //     console.log('percentage:'+percentage);
            //     this.setState({uploadProgress: parseInt(percentage.toFixed(0))} );
            // }
        };

        this.setState({ uploadProgress: 1 });
        const fromDateAddition = this.state.dateFrom.split(' ').length==2? this.state.dateFrom: `${this.state.dateFrom} 00:00`;


        let url = `${process.env.REACT_APP_REMOTE_URL}/seller/getOssTemporaryAuthorizationUrl?fileName=${file.name}`;

        Request.get1(url,options).then((res) => {
            //debugger;
            if (res.success) {
                // let ossUrl = res.data.key.filePath;
                // let filePrefix = res.data.key.filePrefix;
                // let result: any = [
                //     { status: "uploading", uid: file.uid, percent: 0 },
                // ];
                //debugger;
                let  reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = () => {
                    Request.putOss(EHostType.NONE, res.data.value.url, reader.result, res.data.value.contentType, (percent) => {
                            if (percent === 100) {
                                console.log('put oss finished');
                                //debugger;
                                //延迟修改STATE,因为上传成功到能下载，OSS有时间差
                                setTimeout(()=>{
                                    this.setState({
                                        fileUploadedNum:this.state.fileUploadedNum+1,
                                        materialList: [...this.state.materialList,res.data.key.filePath]
                                    } );
                                },2000);
                            }
                        }
                    )
                        .then((res) => {
                            console.log("res:"+res);
                        })
                        .catch((err) => {

                            console.log("Upload failed");
                        });
                };

            }
        });




        // let res =await axios.post( process.env.REACT_APP_REMOTE_URL+"/seller/uploadActFile?actType="+this.state.actType, formData, options);
        // if( res.status == 200 ){
        //     let resData = res.data;
        //     if( resData.isSuccess ){
        //         let data =resData.data;
        //         if( !util.isVideo( data.filePathName ) ) {
        //             data['faceUrl'] = URL.createObjectURL(file);
        //         }
        //         console.log('uploadProgress:100');
        //         //this.curFileNumToUpload=this.curFileNumToUpload+1;
        //         this.setState({
        //             uploadProgress:100,
        //             fileToUploadNum:this.state.fileToUploadNum+1,
        //             materialList: [...this.state.materialList, {path:data.faceUrl,url:data.fileUrl,type:1}]
        //         } );
        //     }else{
        //         this.setState({uploadProgress:0});
        //         Modal.error({content:resData.errroMsg});
        //     }
        // }else{
        //     // util.error(response); //
        // }


    }


    getUploadedPercentage(){
        let i=this.state.fileToUploadNum;
        let j=this.state.fileUploadedNum;
        let result=1;
        if(i==0){
            result=100;
        }else{
            result= Math.trunc(j*100/i);
        }
        console.log('getUploadedPercentage result:'+result);
        return result;
    }


    shouldShowUploadProgress(){
        let percentage = this.getUploadedPercentage();
        if(this.state.fileUploadCancelled){
            return false;
        }
        if( this.state.fileToUploadNum >0 &&  percentage !=100 ){
            return  true;
        }
        return false;
    }



    uploadFiles(e:any){
        let files = e.target.files;
        if(files){
            let i=1;
            let len=files.length;
            //this.fileTotalNumToUpload=len;
            // this.curFileNumToUpload=0;
            this.setState({fileToUploadNum:len,fileUploadedNum:0, fileUploadCancelled:false});
            try{
                for(let i=1;i<=len;i++){
                    this.uploadSingleFiles(files[i-1]);
                }
            }catch (e){
                console.log(e);
            }
        }



    }

    cancelUpload(){
        if( this.uploadCancelSource ){
            this.uploadCancelSource.cancel();
        }
        this.setState({
            fileUploadCancelled:true
        })
    }

    deleteFile(index:any){
        let list = this.state.materialList;
        list.splice(index, 1);
        // let num=list.filter((item:any)=>{
        //     return item.type===1
        // })
        this.setState({
            materialList:list
        });
    }

    validateFailed( errorInfo:any){
        //验证required
        let message = '';
        for( let info of errorInfo.errorFields ){
            message += info.errors.join(';') + '<br />';
        }
        util.message( message );
    }

    deleteVideoDescription(i:number){
        let currentCount = this.state.videoDescriptionCount;
        currentCount[i] = -1;
        this.setState({videoDescriptionCount: currentCount});
    }

    selectTopics(topics:any){
        this.setState({videoTopic: topics});
    }

    convertTopics(topicList:Array<any>){
        let result:any = [];
        if( topicList ){
            for( let item of topicList ){
                result.push({id: item.key, name: item.value});
            }
        }
        return result;
    }

    
    // materialData=[];
    //
    // onMaterialChanged(data:any){
    //     if(data){
    //         let materialData=[];
    //         data.map((item: { path: any; })=>{
    //             materialData.push(item.path);
    //         })
    //     }
    //     console.log("materialData:"+this.materialData);
    // }


    render() {
        console.log("rendering...");
        let currentStep = this.state.currentStep;
        if( this.state.loading ){
            console.log("loading...");
            return <LoadingOutlined />;
        }

        let data = this.state.data;

        console.log("return html...");

       let  ossPrefix=process.env.REACT_APP_OSS_PREFIX;

        // @ts-ignore
        return (<div className='createActWrap'>
            <div className="steps relative createActStepHeader">
                <Steps direction="horizontal" current={currentStep}>
                    {steps.map((step, index) =>
                        <Step title={<div className={currentStep == index ? ' step-arrow' : ''}>{step}</div>}
                              icon={<span className="step-index">{index+1}</span>} />
                    )}
                </Steps>
            </div>
            <Card className='createActContent'>
                <Form ref={this.formRef}
                      name="activity"
                      onFinish={(values)=>this.submit(values)}
                      requiredMark={false}
                      validateTrigger="onSubmit"
                      onFinishFailed={(errorInfo:any)=>this.validateFailed(errorInfo)}
                      size="large">
                    <div className="action-main">
                        <div style={{display:this.state.currentStep == 0?'block':'none' }}>

                            <Form.Item
                                label="Campaign Name"
                                name="actName"
                                initialValue={data.actName?data.actName:"tiktok campaign"+moment(new Date()).format('MMDD')}
                                rules={[{ required: true, message:'Please enter the campaign name' }]}>
                                <Input placeholder="Please enter the campaign name" maxLength={30} />
                            </Form.Item>

                            <Form.Item label="Campaign time" className="box-row-between-m">
                                <div>From:<DateTime placeholder="start time" min={moment().subtract(2, 'days').toDate()} dateonly={true} value={this.state.dateFrom} onSelect={(value:any)=>{this.setState({dateFrom: value}); console.log(value)}} /></div>

                                <div>To:<DateTime placeholder="end time" dateonly={true} min={moment().subtract(1, 'days').toDate()} value={this.state.dateTo} onSelect={(value:any)=>{this.setState({dateTo: value}); console.log(value)}} /></div>
                            </Form.Item>

                            <Form.Item validateFirst={false} name="actDescription" initialValue={data.actDescription} label="Campaign Description" rules={[{ required: true, message:'please input activity introduction' }]}>
                                <TextArea rows={5} showCount maxLength={200} placeholder="Campaign Description"></TextArea>
                            </Form.Item>

                            <Form.Item name="actType"  hidden={true} initialValue={2}  />
                            <Form.Item name="videoPublishType"  hidden={true} initialValue={1}  />


                        </div>

                        <div style={{display:this.state.currentStep == 1?'block':'none' }}>
                            <AddTopic title={this.state.data.title} data={this.convertTopics(this.state.data.topicList)} onChange={(topics:any)=>this.selectTopics(topics)} />
                            <div style={{display:"none"}}>
                                <Form.Item label="Upload Video (mp4 only)">
                                    <div>
                                        <div className="input-note text-xs">Tip: A single video requires no more than 30 seconds, and a single file size of no more than 100MB for better results！</div>

                                        <div>

                                            { this.shouldShowUploadProgress() &&
                                            <div className="progress">
                                                {/*<p>本次共选择了{this.fileTotalNumToUpload}个视频,已成功上传{this.curFileNumToUpload}个</p>*/}
                                                {/*<Progress type="circle" percent={this.state.uploadProgress} width={80} />*/}
                                                <Progress type="circle" percent={this.getUploadedPercentage()} width={80} />
                                                <p><br />Please wait while the file is being uploaded, do not let the phone rest... <br /></p>
                                                <Button type="primary" danger={true} onClick={()=>this.cancelUpload()}>Cancel Upload</Button>
                                            </div>
                                            }

                                            <div className="material-thumnail text-space ">
                                                {
                                                    console.log('materialList1:'+this.state.materialList)
                                                }
                                                {this.state?.materialList?.map( (item, index) =>
                                                    <div className="materialItem">
                                                        <div>
                                                            {/*<img src={item.path} style={{width:"103px" ,height:"103px", top:"50%" }} />*/}

                                                            <video
                                                                width={100}
                                                                height={100}
                                                                className="ddlktaskmaterialvideo"
                                                                src={ossPrefix+"/"+item}
                                                                controls
                                                            ></video>



                                                        </div>

                                                        {/*{item.type===1&&<img src={videoTag} style={{width:"24px",height:"24px",position:"absolute",bottom:"4px",left:"4px"}} />}*/}

                                                        <span className="close">
                                                            <a href="#" onClick={(e)=>{e.preventDefault();this.deleteFile(index)}}>
                                                                <div className="icon-close"></div>
                                                            </a>
                                                            </span>
                                                    </div>)}


                                                {this.state.actType==2&&<div className="upload-buttons box-column-m-m ">
                                                    <label>
                                                        <input type="file" style={{width:'100%',height:'100%',position:'absolute',top:'0',left:'0',opacity:0}}  accept="video/mp4" onChange={(e)=>this.uploadFiles(e)} multiple={true} />
                                                    </label>
                                                    <span  className="text-xs" style={{marginTop:'24px'}}>Upload Video</span>
                                                </div>}


                                                {/*<Uploader*/}
                                                {/*    multiple={true}*/}
                                                {/*    upload={upload}*/}
                                                {/*     defaultValue={demoData}*/}
                                                {/*    maxCount={500}*/}
                                                {/*    maxSize={50 * 1024*1024}*/}
                                                {/*    accept="video/*"*/}
                                                {/*    onOversize={() => Toast.info('文件大小不能超过50mb')}*/}
                                                {/*/>*/}

                                                {/*<TabMaterial*/}
                                                {/*    dataSource={this.state.data.materialList?[...this.state.data.materialList]:[]}*/}
                                                {/*    filePrefix={this.state.data.ossPrefix}*/}
                                                {/*    // tabIndex={'1'}*/}
                                                {/*    onChange={(data: IDataItem[]) => {*/}
                                                {/*        this.onMaterialChanged(data);*/}
                                                {/*    }}*/}
                                                {/*    requireMaterialNumPermit={-1}*/}
                                                {/*    uploadVideOnly={ true }*/}
                                                {/*    maxMaterialNumPermit={  500 }*/}
                                                {/*/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </Form.Item>
                            </div>

                        </div>

                        <div  style={{display:this.state.currentStep == 2?'block':'none' }}>
                            <Form.Item label="Select a campaign coupon">
                                <div>
                                    <div className="bkm-tab-button">
                                        {['all', 'cash coupon', 'discount coupon', 'gift coupon'].map((text:string, index:number)=>
                                            <span style={{width:100/4+'%'}} className={this.state.couponType==index?'selected':''} onClick={()=>this.setState({couponType: index},()=>{
                                                const selectCoupons = this.state.coupons.filter((item:any)=>{ return item.type==index });
                                                if(selectCoupons.length>0){
                                                    this.formRef.current.setFieldsValue({couponIdList:selectCoupons[0].id+""})
                                                }
                                            })}>{text}</span>)}
                                    </div>
                                    <br />
                                    <div>
                                        <Form.Item name="couponIdList" rules={[{required: this.state.currentStep==2, message:'please choose a coupon'}]} initialValue={data.couponIdList}>
                                            <Radio.Group style={{width: '100%'}}>
                                                {this.state.coupons.map((item:any)=>
                                                    <>{((this.state.couponType==0||this.state.couponType==item.type) && !util.isCouponExpired( item ) )&& <label><Row className="row-coupon coupon-white">
                                                        <Col span={22}><Coupon viewOnly={true} data={item} /><br /></Col>
                                                        <Col span={2} className="radio-coupon"><Radio value={item.id+''} /></Col>
                                                    </Row></label>}</>
                                                )}
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form.Item>
                        </div>
                    </div>

                    <div className="action-bottom">
                        <div>
                            {Object.values(this.state.validation).map((message:any)=>
                                <div>{message}</div>
                            )}
                        </div>
                        <div className="box-row-between-m bottomActionFixed box-bg-def" style={{height:"50px"}}>
                            <div style={{width:"150px",height:"50px",display:"flex"}} className="box-row-around-m">
                                {currentStep==0&&<div className="box-column-m-m"  onClick={()=>{window.location.href="/seller/activities"}} style={{height:"50px",padding:"4px 0"}}>
                                    <img src={iconBack}   width={24} height={24}/>
                                    <Button type="link" className="text-xxs">Go Back</Button>
                                </div>}
                                {currentStep!=0&&<div className="box-column-m-m" style={{height:"50px",padding:"4px 0"}} onClick={() => this.previousStep()}>
                                    <img src={iconBack}   width={24} height={24}/>
                                    <Button   type="link" className="text-xxs" >Go Back</Button>
                                </div>}

                                <div className="box-column-m-m" style={{height:"50px",padding:"4px 0",marginLeft:"-20px"}} onClick={()=>this.saveDraft()}>
                                    <img src={iconSave} alt="Save Draft" width={24} height={24}/>
                                    <Button type="link" className="text-xxs" disabled={this.state.saving}>Save Draft</Button>
                                </div>
                            </div>

                            {currentStep!=2&&<Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                style={{flex:1,marginLeft:"30px",height:"50px",background:"linear-gradient(90deg, rgba(255, 123, 80, 1) 0%, rgba(255, 37, 37, 1) 100%)"}}>Next step</Button>}

                            {currentStep==2&&<Button disabled={this.state.saving} size="large" type="primary" htmlType="submit" style={{flex:1,marginLeft:"30px",height:"50px",background:"linear-gradient(90deg, rgba(255, 123, 80, 1) 0%, rgba(255, 37, 37, 1) 100%)"}}>Submit to review</Button>}
                        </div>
                    </div>
                </Form>
            </Card>

        </div>);
    }

}

export const Video = (props:{data:any})=>{
    const [duration, setDuration] = useState('');
    return  (
        <div>
            <video onClick={(e) => util.playVideo(e.target) }
                   poster={props.data.faceUrl}
                   onLoadedMetadata={e => {
                       let target:any = e.target;
                       setDuration(util.formatDuration(target.duration));} }
                   src={props.data.url}
                   width="100%" />
            <div className="video-duration">{duration}</div>
        </div>
    );
}

export const TextAreaWithCount = (props:any)=>{
    const [value, setValue] = useState(props.initialValue?props.initialValue:'');

    return  <>
        <Form.Item {...props}>
            <TextArea maxLength={100} onChange={(e)=>setValue( e.target.value )} placeholder="Please enter a video introduction" rows={6} />
        </Form.Item>

        <span className="video-desc-strcount light">{value.length}/100</span></>;
}


const AddTopic = (props:{onChange:any, title:string, data:Array<any> }) =>{
    const [showDialog, setShowDialog] = useState(false);
    const [topics, setTopics] = useState(props.data);
    let querying = false;

    const [data, setData] = useState([]);


    useEffect(() => {
        if(showDialog){
            query('');
        }
    }, [showDialog, topics])

    const query = (text:any) =>{
        querying = true;
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/activity/topic/search?keywords="+text,
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then((data:any)=>{
                querying = false;
                setData(data);
            })
            .catch(()=>{querying=false});
    }

    const select = (topic:any)=>{
        let existing = topics.find((item)=>item.id==topic.id)
        if( !existing ){
            let newTopics = [...topics, topic];
            setTopics(newTopics);
            props.onChange(newTopics);
        }
        setShowDialog(false);
    };

    const deleteTopic = (value:any)=>{
        let newTopics = [];
        for( let item of topics ){
            if( item.id != value ){
                newTopics.push(item);
            }
        }
        props.onChange(newTopics);
        setTopics( newTopics);
    }

    const getTopicStr = ()=>{
        let resultArr = [];
        for( let item of topics ){
            resultArr.push( item.id );
        }
        return resultArr.join(',');
    }

    return <div>
        <Form.Item name="videoTitle" label="Video Title" initialValue={props.title}>
            <Input type="text" placeholder="For example, finding a good store nearby" maxLength={200} />
        </Form.Item>

        {topics.length>0&&<div className="space-text">
            {topics.map((topic:any)=><div className="topic-item">#{topic.name}
                <span className="topic-remove">
                    <a href="#" onClick={(e)=>{e.preventDefault(); deleteTopic(topic.id)}}>
                        <CloseOutlined style={{fontSize: '14px', color: '#2D49FE'}} />
                    </a>
                </span></div>)}
            <br /><br />
        </div>}

        <div>
            <button className="bkm-btn-fill text-danger" style={{background:"#ffebeb",border:"1px dotted #ff1e1e"}} type="button" onClick={()=>setShowDialog(true)}>#Add Topic</button>
        </div>

        {showDialog&&<div className="overlay-full">
            <Row>
                <Col span={15}><input onChange={(e)=>query(e.target.value)} className="topic-filter" placeholder="#" type="text" /></Col>
                <Col span={4}><Button type="link" onClick={()=>setShowDialog(false)}><span className="light">Cancel</span></Button></Col>
            </Row>
            <div className="topic-list">
                {data&&data.map((item:any)=><div>
                    <div onClick={()=>select(item)}>#{item.name}</div>
                </div>)}
            </div>
        </div>}
    </div>
}