import React from 'react';
import { Button, Menu, Modal, Popover, Space, Table } from 'antd';
import { Switch, BrowserRouter as Router, Route, Link, useLocation, Redirect } from "react-router-dom";
import { Row, Col } from 'antd';
import { util } from '../../utils/Util';
import Moment from 'react-moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import {LoadingOutlined} from "@ant-design/icons";
import {SegmentedControl, WingBlank} from "antd-mobile";
import Placeholder from "../components/placeholder/placeholder";
import recordsNull from "../../assets/img/records-null.png";


export default class CouponsList extends React.Component<{customerToken:string}, {error: boolean,status:any,pageNum:number,loadedPage:number,couponType:string, statData:any, data:Array<any>,nodata:boolean,hasMore:boolean}>{
    private token:any = '';
    constructor(props:any){
        super(props);
        this.state = {
            status: 0,
            pageNum:1,
            data:[],
            loadedPage: 1,
            couponType:"0",
            statData:'',
            nodata: false,
            hasMore:true,
            error: false,
        }
    }

    componentDidMount(){
        if( util.getLocationParam('error') ){
            this.setState({error: true});
            return;
        }
        if( !this.props.customerToken ){
            this.setState({error: true});
            return;
        }

        this.fetchData(1);
        // this.fetchStat();
    };

    componentDidUpdate(prevProps:any, prevState:any){
        if(prevState.couponType != this.state.couponType){
            // this.fetchStat();
        }
    }


    fetchData(pageNum:any){
        fetch(process.env.REACT_APP_REMOTE_URL+"/customer/coupon/recordByCustomerId?customerToken="+this.props.customerToken+"&status="+this.state.status+"&pageNum="+pageNum,
            {method: 'POST'})
            .then(res=>util.handleResponse(res))
            .then(data=>{
                this.setState({hasMore:data.length>=10})

               if(data.length){
                   this.setState({
                       status:this.state.status ,
                       data:[...this.state.data,...data],
                       loadedPage: pageNum
                   });
               }
               // else{
                   // if( pageNum == 1 ){
                   //     this.setState({nodata: true});
                   // }else{
                   //     this.setState({loadedPage: 0});
                   // }

               // }
            })
            .catch(()=>{});
    }

    changeType(e:any){
        this.setState({status: e.target.value,data:[]},()=>{
            this.fetchData(1)
        });
    }
    loadMore(){
        console.log(this.state.loadedPage)
        this.fetchData(this.state.loadedPage+1);
    }
//分段器切换
    onSegmentChange = (e:any) => {
        this.setState({ status: e.nativeEvent.selectedSegmentIndex,data:[] },()=>{
            this.fetchData(1)
        })
    }

    render(){

        const { data,hasMore }= this.state;

        return (<div>
            <div className="coupons-summary">
                <WingBlank size="lg" style={{paddingBottom:"13px"}} className="box-row-m-m">
                    <SegmentedControl
                        values={['Unused', 'Expired']}
                        tintColor={'#ff1e1e'}
                        style={{width: '200px', height: '36px'}}
                        selectedIndex={this.state.status}
                        onChange={this.onSegmentChange}/>
                </WingBlank>
            </div>

            <div className="coupons-main" id="scrollableDiv">
                {data.length>0&&<InfiniteScroll
                    scrollableTarget="scrollableDiv"
                    dataLength={data.length}
                    next={()=>this.loadMore()}
                    hasMore={hasMore}
                    loader={hasMore?<div className="center"><LoadingOutlined style={{fontSize:"18px"}}/></div>:''}>
                    {data.map((item)=>
                        <>
                            <div className="row-coupon">
                                <Link to={"/c/"+item.code}><UserCoupon data={item} key={item.id} refresh={()=>this.fetchData(1)} /></Link>
                            </div>
                        </>
                    )}
                </InfiniteScroll>}
                {data.length==0&&<div className="box-row-m-m box-bg-def placeholderWrap"  style={{width:"100%",height:"580px"}}>
                        <Placeholder
                            src={<img src={recordsNull} alt="Coupon logo" style={{width:"149px",height:"114px"}}/>}
                            title=""
                            message="no records"
                            button={<div></div>}></Placeholder>
                    </div>}
                {!hasMore&&data.length>0&&<div className="center light"><br />No more</div>}
            </div>
        </div>
        );
    }

}

export const Coupon = (props:{data:any, refresh?:any, viewOnly?:boolean})=>{

    const isExpired = util.isCouponExpired(props.data);

    if( !props.data ){
        return null;
    }

    return <Row className={isExpired?'coupon-expired':''}>
        <Col span={8}  className="coupon-info">
            <div className={"coupon-status"+" coupon-status-"+props.data.status}>
                {!isExpired&&props.data.status==1&&<span>in use</span>}
                {isExpired&&<span>Expired</span>}
            </div>
            <div>
                <CouponSummary key={props.data.id} data={props.data} />
            </div>
        </Col>

    </Row>;
}
export const UserCoupon = (props:{data:any, refresh?:any, viewOnly?:boolean,createdTime?:string,expireTime?:string})=>{


    if( !props.data ){
        return null;
    }

    return <Row className={props.data.status!=0?'coupon-expired':''}>
        <Col span={8}  className="coupon-info">
            <div className={"coupon-status"+" coupon-status-"+props.data.status}>
                {props.data.status==1&&<span>Written off</span>}
                {props.data.status==2&&<span>Expired</span>}
            </div>
            <div>
                <CouponSummary key={props.data.id} data={props.data.coupon} />
            </div>
        </Col>
        <Col span={16} className="coupon-body box-content">
            <h3>Seller：{props.data.seller.name}</h3>
            <div className="text-xs text-def" style={{margin:"15px 0 5px"}}>{props.data.coupon.name}</div>
            <div className="box-row-start-m">
                <label>Valid: </label>
                {props.data.coupon.timeType==1&&<div><span><Moment format="YYYY.MM.DD">{props.data.coupon.dateFrom}</Moment></span> ~ <span><Moment format="YYYY.MM.DD">{props.data.coupon.dateTo}</Moment></span></div>}
                {props.data.coupon.timeType==2&&<div><span> is valid after receiving in  {props.data.coupon.validDates} days</span></div>}
            </div>
        </Col>
    </Row>;
}

export const CouponSummary=(props:{data:any, oneLine?:boolean, prefix?:boolean})=>{
    const prefix = ()=>{
        if( props.prefix) {
            let text = '';
            if( props.data.type==0 ){
                text = '';
            }else if( props.data.type==1 ){
                text = 'Written off';
            }else if( props.data.type==2 ){
                text = 'Expired';
            }
            return <span className="coupon-type">[{text}]</span>;
        }else{
            return '';
        }
    }

    if( props.data.type==1 ){
        let discount = props.data.discount/100;
        let least = props.data.least/100;
        if( props.oneLine ){
            return <>{prefix()}<span>Minus {discount}  from {least}  </span></>
        }
        return <>
            <h3><span></span>{discount}</h3>
            <span className="coupon-note">Available at {least} or more </span>
        </>;
    }else if( props.data.type==2 ){
        if( props.oneLine ){
            return <>{prefix()}<span>{props.data.discountRate} off</span></>
        }
        return <h3>{props.data.discountRate}<span className="yuan">off </span></h3>;
    }else if( props.data.type==3 ){
        if( props.oneLine ){
            return <>{prefix()}<span>{props.data.giftName}</span></>
        }
        return <h3 className="giftname">{props.data.giftName}</h3>;
    }
    return <></>;
}