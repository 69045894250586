import React, { useEffect, useState } from "react";
import { Button, WhiteSpace, WingBlank } from 'antd-mobile';


const Placeholder=(props:any)=>{
    return (
        <div>
            <div className="box-column-m-m" style={{width:"100%"}}>
                <div>{props.src}</div>
                <div className="text-def text-l" style={{margin:"10px 0"}}>{props.title}</div>
                <div className="text-def3 text-xs">{props.message}</div>
                <div>
                    {props.button}
                </div>
            </div>
        </div>
    )
}

export default Placeholder;