import React from 'react';
import { Alert, Button, Col, Menu, Row, Space, Table } from 'antd';
import { Switch, BrowserRouter as Router, Route, Link, useLocation, Redirect } from "react-router-dom";
import { RightOutlined } from '@ant-design/icons';
import './index.css';
import { util } from '../../utils/Util';
import axios from 'axios';
import usedIcon from "../../assets/icon/icon_used.png";
import expiredIcon from "../../assets/icon/icon_expired.png";

export default class CouponCode extends React.Component<{ id: string }, { data: any, activity: any, status: any }>{
    constructor(props: any) {
        super(props);
        this.state = {
            data: '',
            activity: '',
            status: ''
        }
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_REMOTE_URL}/coupon/${this.props.id}`).then(res=>res.json())
        .then((value)=>{
            let imgUrl;
            if (value.data.status === 1) {
                imgUrl = usedIcon;
            } else if (value.data.status === 2) {
                imgUrl = expiredIcon;
            }
            this.setState({
                data: value.data,
                status: imgUrl
            })
        })
        .catch(()=>{});
    };

    render() {
        const { data, status } = this.state;
        return (
            <div className="wrap">
                <div className="card-code">
                    <div className="status">
                        {status !== undefined && status !== '' ? <img src={status} alt="Coupon status icon" width="64px" height="64px"/> : null}
                    </div>
                    <div className="head">
                        <div className="tit">
                            <span>{data.coupon?.type==1?'[cash coupon]':(data.coupon?.type==2?'[discount coupon]':'[gift coupon]')} {data.coupon?.name}</span>
                            <span>{data !== undefined && data !== '' ? data.coupon.giftName : 'no desc...'}</span>
                        </div>
                        <div className="info"><span className="text-def3">activity seller ：</span><span>{data?.seller?.name}</span></div>
                    </div>

                    <div className="code-info">
                        <div className="code-number">coupon ID：{data.code}</div>
                        <div className="code-img">
                            {data.qrCode !== undefined && data.qrCode !== '' ? <img src={data.qrCode} alt="" /> : <div className="code-img-alt">Unable to obtain QR code</div>}
                        </div>
                        <div className="code-tip">Show it to the shop assistant when paying</div>
                        {data.coupon&&<div className="coupon-desc"><h3>Coupon usage instructions </h3>{data.coupon.description}</div>}
                    </div>
                </div>
            </div>
        );
    }

}

