import React, { useEffect, useState } from 'react';
import {  Button, Col,  Modal, Popover, Row, Space, Table } from 'antd';
import { Modal as XMModal } from 'antd-mobile';
import { Link } from "react-router-dom";
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { CalcVideoCount, Format, util } from '../../utils/Util';
import { CouponSummary } from './Coupons';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'moment/locale/zh-cn';
import stopedIcon from '../../assets/icon/icon-activity-stoped-en.png';
import arrowDownIcon from '../../assets/svg/arrow_down.svg';
import arrowUpIcon from '../../assets/svg/arrow_up.svg';
import Placeholder from "../components/placeholder/placeholder";
import activityNull from "../../assets/img/activity-null.png";


export default class Activities extends React.Component<{}, {loadedPage:number, ongoing:any, data:Array<any>, nodata:boolean}>{   
    private excludedId:any = '';

    constructor(props:any){
        super(props);
        this.state = {
            loadedPage: 1,
            ongoing:'',
            data:[],
            nodata: false
        }
    }

    componentDidMount(){
        this.fetchAll();
    };

    fetchAll(){              
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getDoingAct",
        {headers:{Authorization: util.getToken()}})
        .then(res=>util.handleResponse(res))
        .then(data=>{
            if( data ){
                if( data ){
                    this.excludedId = data.id;
                    this.setState({ongoing:data});     
                }             
            }         
            this.fetchData(1);
            
        }).catch(()=>{});
    }

    fetchData(page:any){ 
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getActList?pageNo="+page,
        {headers:{Authorization: util.getToken()}})
        .then(res=>util.handleResponse(res))
        .then( data=>{
            if( data.length ){
                data = data.filter((item:any)=>!(this.excludedId&&item.id==this.excludedId));
                this.setState({data:[...this.state.data,...data], loadedPage: page});
            }else{
                if( page == 1 ){
                    this.setState({nodata: true});                
                }else{
                    this.setState({loadedPage: 0});                
                }
            }
        }).catch(()=>{});
    }

    loadMore(){
        this.fetchData(this.state.loadedPage+1);
    }

    createAct(){
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/coupon/usableNumber",
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then((data:any)=> {
                if(data === 0){
                    XMModal.alert('Coupon Hints', 'There are currently no available coupons. Please create a valid coupon before creatinga campaign.', [
                        { text: 'Cancel', onPress: () => console.log('cancel'), style: 'default' },
                        { text: 'Create Coupon', onPress: () =>{window.location.href="/seller/coupon/create"} }
                    ]);
                }else{
                    window.location.href="/seller/activity-create";
                }
            }).catch(()=>{});
    }
   
    render(){               
        let data = this.state.data;        

        return (<div className="user">
            <div className="activities-main" id="main">
            {this.state.nodata&&<div className="box-row-m-m placeholderWrap"  style={{width:"100%",height:"100%"}}>
                <Placeholder
                    src={<img src={activityNull} alt="Activity is empty logo" style={{width:"149px",height:"114px"}}/>}
                    title="Currently no campaign"
                    message="You haven't created a new campaign yet"
                    button={<Button className="btn-createActivity text-normal text-s box-row-m-m" onClick={this.createAct}>Create</Button>}></Placeholder>
            </div>}

            {this.state.ongoing&&<><Activity key={this.state.ongoing.id} data={this.state.ongoing} /><br /></>}
            {data.length>0&&<div>
                 <InfiniteScroll
                        scrollableTarget="main"
                        dataLength={data.length}
                        next={()=>this.loadMore()}
                        hasMore={data.length>=9}
                        loader={this.state.loadedPage!=0?<div className="center"><LoadingOutlined /></div>:''}>
                    {data.map((item)=>
                        <Activity key={item.id} data={item} />
                    )}
                </InfiniteScroll>
                            
                {this.state.loadedPage==0&&<div className="center light"><br />No more...</div>}
            </div>}

                <br /><br />
            </div>
            {!this.state.nodata&&<div className="bottom-tool">
                <a href="#"  className="text-danger"  onClick={this.createAct}>
                   Create
                 </a>
            </div>}
        </div>);
    }

}

//活动Item
export const Activity = (props:{ data:any}) => {

    const isOngoing = props.data.status==3&&!util.isAcitivityTimeOver(props.data);
    const isOver = props.data.status==3 && util.isAcitivityTimeOver(props.data);
    const showPosterBtn=props.data.status==3&&props.data.statusName!='is over'||props.data.status==1;

    const [open, setOpen] = useState(isOngoing?true:false);
    const [ended, setEnded] = useState(props.data.status==4?true:false);
    const [enabled, setEnabled] = useState(props.data.enabled);
    const [poster, setPoster] = useState<any>('');
    const [showPoster, setShowPoster] = useState(false);
    const [info, setInfo] = useState<any>('');
    const [showAdjustPoints, setShowAdjustPoint] = useState(false);
    const [adjustedPoints, setadjustedPoints] = useState('');
    const [sending, setSending] = useState(false);
    const [videoCount, setVideoCount] = useState('');

    const enable = (enable: boolean)=>{
        let url = enable?'enableAct':'disableAct';
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/"+url+"?id="+props.data.id,
        {headers: {'Authorization': util.getToken()}})
        .then(res=>util.handleResponse(res))
        .then(data=> setEnabled(enable)).catch(()=>{});
    }

    const clickShowPoster = ()=>{
        if( !poster ){
            fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getActById?id="+props.data.id,
            {headers: {'Authorization': util.getToken()}})
            .then(res=>util.handleResponse(res))
            .then(data=>{
                setPoster(data.posterInfo);
                setShowPoster(true);
            }).catch(()=>{});
        }else{
            setShowPoster(true);

        }
    }

    // const getPoints = ()=>{
    //     fetch(process.env.REACT_APP_REMOTE_URL+"/seller/info",
    //         {headers:{Authorization: util.getToken()}})
    //         .then(res=>util.handleResponse(res) )
    //         .then( (data) => setInfo(data) )
    //         .catch(()=>{});
    //     // setShowAdjustPoint(true)
    // }

    // const confirmAdjust = ()=>{
    //     let adjustedPointsInt = parseInt(adjustedPoints);
    //     if( isNaN(adjustedPointsInt) ){
    //         util.message("请输入数字");
    //         return;
    //     }
    //     if( adjustedPointsInt <=0 ){
    //         util.message("视频点数不能为0或负");
    //         return;
    //     }
    //     let newPoints = props.data.maxPoints + adjustedPointsInt;
    //     setSending(true);
    //     fetch(process.env.REACT_APP_REMOTE_URL+"/seller/modifyActMaxPoints?id="+props.data.id+'&newMaxPoints='+newPoints,
    //     {headers: {'Authorization': util.getToken()},
    //     method:'POST'})
    //     .then(res=>util.handleResponse(res))
    //     .then(data=>{
    //         setSending(false);
    //         Modal.info({content: '调整成功', onOk: ()=>{
    //             window.location.href = window.location.href;
    //         }});
    //     }).catch(()=>{});
    // }

    const retract = ()=>{
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/retractAct?id="+props.data.id,
        {headers: {'Authorization': util.getToken()}})
        .then(res=>util.handleResponse(res))
        .then(data=>{
            window.location.href = window.location.href;
        }).catch(()=>{});
    }

    const endActivity = (e:any)=>{
        e.preventDefault();
        Modal.confirm({
            onOk: ()=>{
                fetch(process.env.REACT_APP_REMOTE_URL+"/seller/closeAct?id="+props.data.id,
                {headers: {'Authorization': util.getToken()}})
                .then(res=>util.handleResponse(res))
                .then(data=>{
                    Modal.info({
                        onOk: ()=>window.location.href = window.location.href,
                        content: <p>is closed</p>
                    });
                }).catch(()=>{});
            },
            cancelText:'Cancel',
            okText:'Confirm',
            content:<p>Confirm Closing Activity?</p>});
    }

    const deleteDraft = (e:any)=>{
        e.preventDefault();
        Modal.confirm({
            content:'Confirm Delete Draft',
            cancelText:'Cancel',
            okText:'Confirm',
            onOk:()=>{
                fetch(process.env.REACT_APP_REMOTE_URL+"/seller/deleteAct?id="+props.data.id,
                {headers: {'Authorization': util.getToken()}})
                .then(res=>util.handleResponse(res))
                .then(data=>{
                    Modal.info({
                        onOk: ()=>window.location.href = window.location.href,
                        content: <p>Draft has been deleted!</p>
                    });
                }).catch(()=>{});
            }
        });
    }

    useEffect(()=>{
        CalcVideoCount(props.data.actType, props.data.materialList?.split(';').length)
        .then((count)=>{
            setVideoCount(count);
         }).catch(()=>{});
    });

    return (
        <div className="activity-item-wrap relative">
            {isOngoing&&!enabled&&<img src={stopedIcon} alt="act status" className="activity-status-icon absolute" />}

            <div className="box">
                {isOngoing&&<PointsNotification id={props.data.id} />}
                {/*{showPoster&&<ActivityPoster posterData={poster} onClose={()=>setShowPoster(false)} />}*/}

                <Link to={"/seller/activity/view/"+props.data.id} className="block-link">
                    <span className={"right activity-status-"+props.data.status+(isOver?' activity-over':'')}>
                            {props.data.statusName}
                    </span>
                    <h3 className="box-row-start-m">

                        {props.data.actName}
                    </h3>
                    <div className="text-space">
                        <Row>
                            <Col><label>campaign rewards ：</label></Col>
                            <Col>{props.data.couponList&&props.data.couponList[0]&&<CouponSummary prefix={true} oneLine={true} data={props.data.couponList[0]} />}</Col>
                        </Row>
                        <Row>
                            <Col><label>campaign time：</label></Col>
                            <Col> {props.data.dateFrom} ~ {props.data.dateTo}</Col>
                        </Row>
                        {/*{![0, 2].includes(props.data.status)&&<Row>*/}
                        {/*    <Col><label>Remaining promotional videos ：</label></Col>*/}
                        {/*    {videoCount!==''&&<Col><span className="red">{(parseInt(videoCount)-props.data.statCard)>0?(parseInt(videoCount)-props.data.statCard):0}</span>（Total{videoCount}）</Col>}*/}
                        {/*</Row>}*/}
                    </div>
                </Link>
                <hr/>
                <div className="activity-tools">
                    <Popover trigger="click" placement="bottom" className="right" content={
                        <div>
                            <p>
                                <Link to={"/seller/activity-create?from="+props.data.id}>Copy</Link>
                            </p>
                            {(isOngoing||props.data.status==2)&&<p><a href="#" onClick={(e)=>endActivity(e)}>Close</a></p>}
                            {props.data.status==0&&<p><a href="#" onClick={(e)=>deleteDraft(e)}>Delete</a></p>}
                        </div>
                    }>
                        <MoreOutlined style={{fontWeight:'bold', marginTop:'5px'}} />
                    </Popover>
                    <Row>
                        <Col span={24}>

                            <Space>
                                {([0, 1, 2].includes(props.data.status))&&<Link to={'/seller/activity-edit/'+props.data.id}>
                                    <Button size="small"  className="tiny">Edit</Button>
                                </Link>}
                                {isOngoing&&<Button size="small" className="tiny" onClick={()=>enable(!enabled)}>{enabled?'Pause':'Resume'}</Button>}
                                {showPosterBtn&&<Link to={`/seller/ActivityPoster/${props.data.id}`}><Button size="small" className="tiny">QR Code</Button></Link>}
                                {(![0, 1, 2].includes(props.data.status))&&<Link to={"/seller/activity/record/"+props.data.id}>
                                    <Button size="small" className="tiny">Records</Button>
                                </Link>}
                                {props.data.status==1&&<Button size="small" className="tiny" onClick={()=>retract()}>Withdrawal of review </Button>}

                                {/*{isOngoing&&props.data.actType==1&&<Button size="small" className="tiny" disabled={sending} onClick={()=>adjustPoints()}>调整视频点数</Button>}*/}

                                {/*{showAdjustPoints&&<Modal className="bkm-modal" visible={true} closable={false} footer={false}>*/}
                                {/*    <div className="adjust-points">*/}
                                {/*        <h3 className="center">调整视频点数</h3>*/}
                                {/*        /!*<Row>*!/*/}
                                {/*        /!*    <Col span={12}><label>活动投放视频点数</label></Col>*!/*/}
                                {/*        /!*    <Col span={12}>{props.data.maxPoints}</Col>*!/*/}
                                {/*        /!*</Row>*!/*/}
                                {/*        <Row>*/}
                                {/*            <Col span={12}><label>活动已用视频点数</label></Col>*/}
                                {/*            <Col span={12}>{-props.data.usedPoints}</Col>*/}
                                {/*        </Row>*/}
                                {/*        <hr />*/}
                                {/*        <Row>*/}
                                {/*            <Col span={12}><label>输入新增视频点数</label></Col>*/}
                                {/*            <Col span={12}><Input style={{width:"100px"}} placeholder="输入新增视频点数" onChange={(e)=>setadjustedPoints(e.target.value)} /></Col>*/}
                                {/*        </Row>*/}
                                {/*        <Row>*/}
                                {/*            <Col span={12}><label>帐户可用</label></Col>*/}
                                {/*            <Col span={12} className="blue bold">{info.points}</Col>*/}
                                {/*        </Row>*/}
                                {/*    </div>*/}
                                {/*    <div className="center">*/}
                                {/*        <br />*/}
                                {/*        <Space>*/}
                                {/*            <Button type="primary" htmlType="submit" size="large" onClick={()=>confirmAdjust()}>确定</Button>*/}
                                {/*            <Button onClick={()=>setShowAdjustPoint(false)} size="large" >取消</Button>*/}
                                {/*        </Space>*/}
                                {/*    </div>*/}
                                {/*</Modal>}*/}
                            </Space>
                        </Col>
                    </Row>
                </div>
                {(isOngoing||isOver||props.data.status==4)&&<div className="activity-stat">
                    <a href="#" onClick={(e)=>{e.preventDefault(); setOpen(!open)}}>
                        <Row className="tool-data">
                            <Col span={20}>
                            </Col>
                            <Col span={4} className="align-right">
                                Data {!open&&<img src={arrowDownIcon} />}{open&&<img src={arrowUpIcon} />}
                            </Col>
                        </Row>
                    </a>
                    {open&&<div className="activity-stat-body"><Row>
                        <Col span={6}>
                            <label>Scanned</label>
                            <h4><Format number={props.data.statScan} /></h4>
                        </Col>
                        <Col span={6}>
                            <label>Videos</label>
                            <h4>
                                <Format number={props.data.statVideo} />
                            </h4>
                        </Col>
                        <Col span={6}>
                            <label>Views</label>
                            <h4>
                                <Format number={props.data.statPlay} />
                            </h4>
                        </Col>
                        <Col span={6}>
                            <label>Likes</label>
                            <h4>
                                <Format number={props.data.statLike} />
                            </h4>
                        </Col>
                    </Row>
                        <Row>
                            <Col span={6}>
                                <label>Coupons: </label>
                                <h4>{props?.data?.couponList?props?.data?.couponList[0]?.totalCount:'-'}</h4>
                            </Col>
                            <Col span={6}>
                                <label>Received:</label>
                                <h4>
                                    {props.data.statCard}
                                </h4>
                            </Col>
                            <Col span={6}>
                                <label>Redeemed</label>
                                <h4>
                                    {props.data.statWriteoff}
                                </h4>
                            </Col>
                            <Col span={6}>
                                <label>Unredeemed</label>
                                <h4>
                                    {props.data.statCard-props.data.statWriteoff}
                                </h4>
                            </Col>
                        </Row>
                    </div>
                    }
                </div>}
            </div>
        </div>
    );
}

//视频点数数不足提示
export const PointsNotification = (props:{id:any}) => {
    const [points,setPoints]=useState<number>(0);
    const [actType,setActType]=useState<any>(0);
    
    useEffect(()=>{
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/info",
            {headers:{Authorization: util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then( (data) => setPoints(data.withholdPoints) ).catch(()=>{});//预扣点

        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getActById?id="+props.id, 
        {headers:{Authorization: util.getToken()}})
        .then(res=>util.handleResponse(res))
        .then(data=>{
            // let points = data.maxPoints - data.usedPoints;
            if(data){
                setActType(data.actType)
            }
        }).catch(()=>{});         
    },[]);


    return  <>{points<1&&actType==1&&<div className="warning" style={{marginBottom: '20px'}}>
                <span>Insufficient video points remaining, please recharge in a timely manner</span>
            </div>}</>;
}


