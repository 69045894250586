import React from 'react';
import {Alert, Button, Input, Modal, Row} from 'antd';
import { util} from '../../utils/Util';
import './JoinActivity.scss';
import './index.css';
import { Toast} from 'antd-mobile';
import coupon from "../../assets/img/coupon.png";
//@ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';

// @ts-ignore
import copy from 'copy-to-clipboard';
import {LinkOutlined} from "@ant-design/icons";



export default class JoinActivity extends React.Component<{}, { showReceive: boolean, error: boolean, needPhone: boolean, status: number, phoneStatus: number, sending: boolean, phone: string, smsCode: string, data: any, activity: any }> {
    private token: any = '';

    constructor(props: any) {
        super(props);
        this.state = {
            error: false,
            needPhone: false,
            phoneStatus: 0,
            data: '',
            activity: '',
            status: 0,
            smsCode: '',
            phone: '',
            sending: false,
            showReceive: false
        }

    }

    componentDidMount() {
        if (util.getLocationParam('error')) {
            this.setState({error: true});
            return;
        }
        this.fetchData();
    };

    fetchData() {
        fetch(process.env.REACT_APP_REMOTE_URL + "/customer/getActById?id=" + util.getLocationParam('activityId'))
            .then(res => util.handleResponse(res))
            .then(data => {
                console.log(data)
                this.setState({data: data})
            }).catch(() => {
        });
    }

    gotoShare() {

        let  activityId = util.getLocationParam('activityId');
        let code = util.getLocationParam('code');
        console.log('activityId:'+activityId);
        console.log('code:'+code);
        let url=process.env.REACT_APP_REMOTE_URL+"/customer/uploading?activityId="+activityId+"&code="+code;
        fetch(url,
            {
                method: "GET",
                headers: {'Content-Type': 'application/json'},
            }).then(function (response) {
            return response.json();
        }).then(data => {
            //debugger;
            if(!data.data){
               // this.copyToClipboard(this.state.data.title + this.convertTopics(this.state.data.topicList));
                console.log('goto inbox')
                window.location.href='snssdk1233://inbox';
            }else{
                Toast.info('Reward distribution in progress!');
            }
        }).catch(() => {
            this.setState({sending: false});
        });
    }





    convertTopics(topicList:Array<any>){
        let s='';
        //debugger;
        if( topicList ){
            for( let item of topicList ){
                s=s.concat("#"+item.value+" ");
            }
        }
        return s;
    }


    getCopyText(){
        return this.state.data.title + this.convertTopics(this.state.data.topicList);

    }

     onCopy = ()=>{
        util.message("video title and topics has been copied");
        this.gotoShare();
    }

    render() {
        console.log('at join activity...');
        if (this.state.error) {
            let errorMessage = util.getLocationParam('errorMessage');
            if (!errorMessage) {
                errorMessage = 'Page error, please contact relevant personnel.';
            }
            return <div className="space-container"><Alert type="error" message={errorMessage}/></div>
        }

        if (!this.state.data) {
            return '';
        }

        let data = this.state.data;

        return (<div className="consumer-join-act">

            <div style={{"height": "90px"}}></div>

            <div className="coupon-giving">
                <div><img src={coupon} alt="Reward distribution in progress!" style={{width: "72px", height: "72px"}}/></div>
                <div style={{"height": "10px"}}></div>
                <div>Reward distribution in progress!</div>
            </div>
            <div style={{"height": "50px"}}></div>

            {this.state.data.title?
                <>
            <div className="coupon-tips-1">
                <div>1.&nbsp;Your tiktok inbox will soon receive a video to be posted. Please help us post this video. Thank you!</div>
                <div>2.&nbsp;Remember to paste the title when publishing the video I have copied it for you.</div>
                <div>3.&nbsp;After the video is successfully released, use your Tiktok username to seek a reward from the boss</div>
            </div>

            <div className="coupon-tips-2">
                <div>Video title: {this.state.data.title}  {this.convertTopics(this.state.data.topicList)}</div>
            </div>
            </>: <></>
            }
            <div style={{"height": "50px"}}></div>
            <div className="goto-share">

                <CopyToClipboard text={this.getCopyText()} onCopy={()=>this.onCopy()}>
                    <Button className="text-s">Publish</Button>
                </CopyToClipboard>


                {/*<button onClick={(e) => this.gotoShare(e)}>*/}
                {/*</button>*/}
            </div>
            <div style={{"height": "100px"}}></div>
        </div>);
    }

}

