import axios, { AxiosResponse,Method } from "axios";
import { util } from "../../utils/Util";
import { Modal} from "antd";
import { Toast } from "antd-mobile";
import React from "react";
// import qs from 'qs'
// 创建axios实例
const service = axios.create({
    baseURL: process.env.REACT_APP_REMOTE_URL,
    timeout: 60000 // 请求超时时间
});

let messageShown = false;
let onOkCallback: (() => void) | undefined = undefined;
let mCustomCatch:boolean|undefined = false;
const codeMessage:any = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
}

// request拦截器
service.interceptors.request.use(
    config => {
        const token = util.getToken();
        if (token) {
            config.headers["Authorization"] = token; // 每个请求携带自定义token 请根据实际情况自行修改
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

const showErrorModal = (resp:any,message:any)=>{
    if(message) {
        Modal.info({
            icon: null,
            okText: '确定',
            onOk: () => {
            },
            content: (message)
        });
        return undefined;
        // new Error(message)
    }
}

const showServerErrorModal = ()=>{
    Modal.info({
        icon: null,
        okText: '确定',
        onOk: () => {
        },
        content: ('服务端错误')
    });
    return undefined
    // new Error('服务端错误')
}

const showLoginModal = (resp:any,message:any)=>{
    if(message&&!messageShown) {
        messageShown = true
        Modal.info({
            icon: null,
            okText: 'ok',
            onOk: () => {
                messageShown = false
                window.location.href="/seller/login"
            },
            content: (<div>Login has expired <div className="light">Click ok to log in again.</div> </div>)
        });
        return undefined
        // return new Error('登录已经失效')
    }
}

const success = (resp:any)=>{
    console.log(resp)
    return resp.data.data;
}

const getResult = (response:any)=>{
    //接口请求成功   弹出报错
    const isRequestSuccess = (response.status==200)?'requestSuccess':'requestFailed';
    //业务成功：返回 response.data.data
    const isSuccess = response.data.isSuccess?'businessSuccess':'businessFailed';
    //401登录报错-  弹出报错，并且退出登录
    const loginError = response.data.errroCode==401?'loginError':'normal';

    let ResultKey = [isRequestSuccess,isSuccess,loginError].filter(item=>{return item}).join('_');
    if(ResultKey.includes('requestFailed')){ ResultKey = 'requestFailed'   }
    console.log(ResultKey)

    const result =  {
        'requestSuccess_businessSuccess_normal':success,
        'requestSuccess_businessFailed_normal':showErrorModal,
        'requestSuccess_businessFailed_loginError':showLoginModal,
        'requestFailed':showServerErrorModal,
    }[ResultKey];


    if(result) {
        return result(response, response?.data?.errroMsg);
    }else{
        return new Error('未知错误类型')
    }
}

// response拦截器
service.interceptors.response.use(
    response => {

        if(!mCustomCatch) {
            const result = getResult(response);
            // console.log(result)
            if (result != undefined) {
                return result
            } else {
                return Promise.resolve(false)
            }
        }else{
            return   response
        }
    },

    (error:any) => {

        const { response } = error;
        if (error === undefined || error.code === 'ECONNABORTED') {
            Toast.fail('服务请求超时')
            return Promise.reject(error)
        }else if (response?.status === 403) {
            Modal.confirm({
                icon: null,
                cancelText:'取消',
                okText: '去续期',
                onOk: ()=>{window.location.href="http://vshop.shxmao.com/mb/shop/index?sid=4032357"},
                content: <div>The account has expired and cannot be logged in at the moment。</div>
            });
        }else{
            const text = codeMessage[response.status] || response.statusText || response.data.errroMsg;
            Toast.fail(`${response.status}:${text}`)
        }
    }
)


const Index = {
    GET:(url:string,params:any,onOk?:any,customCatch?:boolean)=>{
        onOkCallback = onOk;
        mCustomCatch = customCatch
        return service.request({url:url,method:'GET',params:params});
    },
    POST:(url:string,params:any,onOk?:any,customCatch?:boolean)=>{
        onOkCallback = onOk;
        mCustomCatch = customCatch
        return service.request({url:url,method:'POST',data:params,headers:{accept:'*/*','Content-Type': 'application/json'}});
    },
    POSTJSON:(url:string,params:any,onOk?:any)=>{
        onOkCallback = onOk;
        // var fd = new FormData()
        // Object.keys(params).forEach(key=>{
        //     fd.append(key, params[key])
        // })
        return service.request({url:url,method:'POST',data:JSON.stringify(params), headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded'
                'Content-Type': 'application/json'
        }});
    },
    POSTFORM:(url:string,params:any,onOk?:any)=>{
        onOkCallback = onOk;
        var fd = new FormData()
        Object.keys(params).forEach(key=>{
            fd.append(key, params[key])
        })
        return service.request({url:url,method:'POST',data:JSON.stringify(params), headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                // 'Content-Type': 'application/json'
        }});
    },
    DELETE:(url:string,params:any,onOk?:any)=>{
        onOkCallback = onOk;
        return service.request({url:url,method:'DELETE',data:params});
    }
}

export default Index;
