import React from 'react';
import { Button, Col, Form, Input, Menu, Modal, Row, Space, Table } from 'antd';
import { Modal as ZAModal, Cell, Button as ZAButton, Select, Radio } from 'zarm';
import { util } from '../../utils/Util';
import moment from "moment";
import {Toast} from "antd-mobile";

export class UseCoupon extends React.Component<{code?:string}, { input:string, verified:boolean, data:any, submitting: boolean,dateTo:any,dateFrom:any, radioType: any, redeemCode: string}>{
    constructor(props:any){
        super(props);
        this.state = {
            input: props.code?props.code:'',
            verified:false,
            data:'',
            submitting: false,
            dateTo:'',
            dateFrom:'',
            radioType: '0',
            redeemCode: ''
        }
    }

    componentDidMount(){
        if( this.props.code ){
            this.searchCoupon();
        }
    }

    searchCoupon(){
        if(this.state.radioType === '0') {
            let code = this.state.redeemCode;
            if( !code ){
                Modal.info({
                    icon: null,
                    okText: 'Confirm',
                    content:(<p>Please enter Redeem Code:</p>)
                });
                return;
            }
            const formData = new FormData();
            formData.append("redeemCode", code);
            fetch(process.env.REACT_APP_REMOTE_URL+`/seller/coupon/record/redeemByRedeemCode`,{
                headers: {'Authorization': util.getToken()},
                method:'POST', 
                body: formData
            }).then((res:any)=>util.handleResponse(res, ()=>{})).then((res:any) => {
                // console.log('人工核销码', res);
                if(res) {
                    Toast.info("Redeem successfully");
                }
            })
        } else {
            let input = this.state.input;
            if( !input ){
                Modal.info({
                    icon: null,
                    okText: 'Confirm',
                    content:(<p>Please enter a TikTok username:</p>)
                });
                return;
            }
            //获取未核销过的优惠券
            fetch(process.env.REACT_APP_REMOTE_URL+'/seller/coupon/record/listV2/1?status=0&userNick='+input,{
                headers: {'Authorization': util.getToken()}
            }).then((res:any)=>util.handleResponse(res, ()=>{
                if(this.props.code){
                    window.location.href = '/seller';
                }
            })).then((res:any) => {
                console.log(res);
                let couponRecord=null;
                //debugger;
                for(let i in res){
                    couponRecord=res[i][0];
                    break;
                }
                if(couponRecord == null){
                    Toast.info("Name not found. Please check with user.");
                    return ;
                }
                const timeStr = couponRecord?.createdTime.replace(/\./g,'-').replace(/\:/g,'-');
                // // moment对象
                const DataMoment = moment(timeStr,'YYYY-MM-DD');
    
                // //开始时间
                const StartTime =couponRecord.coupon?.timeType==1?couponRecord.coupon.dateFrom: DataMoment.format('YYYY-MM-DD');
                // //结束时间 字符串
                const EndTime= couponRecord.coupon?.timeType==2?DataMoment.add(couponRecord?.coupon?.validDates,'days').format('YYYY-MM-DD'):couponRecord.coupon.dateTo;
    
                this.setState({
                    verified:true,
                    data:couponRecord,
                    dateFrom:StartTime.toString(),
                    dateTo:EndTime.toString(),
                })
            }).catch((e)=>{
                console.log(e)
            });
        }
    }

    close(){
        if( this?.props?.code ){
            window.location.href="/seller";
        }else{
            this.setState({ verified: false });
        }
    }

    useCoupon(){
        this.setState({ verified: false, submitting: true });
        let input = this.state.data.code;
        fetch(process.env.REACT_APP_REMOTE_URL+'/seller/coupon/record/writeoff/'+input,{
            headers: {'Authorization': util.getToken()},
            method:'POST'
        }).then((res:any)=>util.handleResponse(res)).then((data:any)=>{
            Modal.info({
                content: <p>Redeemed</p>,
                onOk: ()=>{window.location.href='/seller'}}
            );
        }).catch(()=>{}).finally(()=>this.setState({submitting: false}));
    }

    render(){
        return (
            <div className="couponDetails" style={{background: 'white', padding: '20px', height:'667px'}}>
                {this.state.data&&<ZAModal
                    width={327}
                    visible={this.state.verified}
                    closable={false}>
                    <div style={{height:"50px",borderBottom:"1px dotted #e1e1e1",margin:"-20px",padding:"0 20px"}} className="box-row-between-m">
                        <div className="text-s text-def">Coupon Details:</div>
                        {this.state.data?.expired&&<div className="text-xs text-def3">Expired</div>}
                    </div>
                    <div className="text-xs text-def">
                        <div className="{!this.state?.data?.expired?'text-def3':'text-danger'  } text-m" style={{padding:"14px 0",margin:"20px 0 0"}}>{this.state.data?.coupon?.name}</div>

                        <div className="box-row-start-m" style={{color:"#bbb"}}>
                            <span style={{width:"92px"}}>Valid:  </span>
                            <span style={{color:!this.state.data.expired?"#333":"#bbb"}} >{this.state?.dateFrom} - {this.state?.dateTo} </span>
                        </div>

                        <div className="box-row-start-m" style={{color:"#bbb"}}>
                            <span style={{width:"92px"}}>Associated Activities:</span>
                            <span style={{color:!this.state.data.expired?"#333":"#bbb"}}>{this.state.data.activityName}</span>
                        </div>

                        <div className="box-row-start-m" style={{color:"#bbb"}}><span style={{width:"92px"}}>Tiktok nickname:  </span>
                            <span style={{color:!this.state.data.expired?"#333":"#bbb"}}>{this.state.data?.customer?.name}</span>
                        </div>
                    </div>
                    <div className="box-row-m-m" >
                        <Button disabled={this.state.data.expired} className="ZABtn" style={{width:"143px",height:"40px",borderRadius:"6px",margin:"15px 10px 0 0"}} onClick={()=>this.useCoupon()}>Redeem</Button>
                        <Button style={{width:"143px",height:"40px",background:"#E6E6E6",borderRadius:"6px",margin:"15px 0px 0 0"}} onClick={()=>this.close()}>Cancel</Button>
                    </div>

                </ZAModal>}

                {!this.props.code&&<div style={{background: 'white', padding:'63px 24px 44px', height:'100vh'}}>
                    <Form onFinish={(values)=>this.searchCoupon()}>
                        {/* <h2 className="text-m text-def" style={{fontFamily: "HarmonyOSSansSC-Regular"}}>Please enter a TikTok username:</h2> */}
                        <Radio.Group 
                            type="button" 
                            value={this.state.radioType} 
                            defaultValue="0"
                            style={{ width: '100%' }}
                            onChange={value => {
                                this.setState({ radioType: value });
                                if(value === '0'){
                                    this.setState({ input: '' })
                                } else {
                                    this.setState({ redeemCode: '' })
                                }
                            }}
                        >
                            <Radio value="0" style={{ width: '40%', height: '40px' }}>Redeem Code</Radio>
                            <Radio value="1" style={{ width: '40%', height: '40px' }}>TikTok Username</Radio>
                        </Radio.Group>

                        <div className="underline">
                            {
                               this.state.radioType === "0" &&  <Form.Item>
                               <Input placeholder="Enter Redeem Code" onChange={(e)=>this.setState({redeemCode: e.target.value})} value={this.state.redeemCode}  defaultValue={this.state.redeemCode}/>
                                </Form.Item>
                            }
                            {
                               this.state.radioType === "1" &&  <Form.Item>
                               <Input placeholder="Enter TikTok Username" onChange={(e)=>this.setState({input: e.target.value})} value={this.state.input}  defaultValue={this.state.input}/>
                           </Form.Item>
                            }
                           
                        </div>
                        <div className="btn-submit">
                            <Button disabled={this.state.submitting} htmlType="submit" className="box-row-m-m">Redeem</Button>
                        </div>
                    </Form>
                </div>}
            </div>)
    }
};
