import React, { useEffect, useRef, useState } from 'react';
import { Button, Input,  Modal } from 'antd';
import { useParams,useLocation } from "react-router-dom";
import { EyeOutlined } from '@ant-design/icons';
import eyeClose from '../../../assets/icon/eye-close.png';
import AgentApi from "../../../api/Agency";
import SellerApi from "../../../api/Seller";
import { Button as ZAButton, Cell, Input as ZAInput,Icon } from "zarm";
import iconMobile from "../../../assets/svg/mobile.svg";
import iconSmsCode from "../../../assets/icon/smscode.png";



interface Props{
    type?:number //type: empty - 商家, 2 - 代理商
}
const  ForgotPassword=(props:Props)=>{

    const { type } = props;
    const [username,setUsername] = useState<string>('');
    const [password,setPassword] = useState<string>('');
    const [passwordRepeat,setPasswordRepeat] = useState<string>('');
    const [status,setStatus]= useState<number>(0);
    const [captchaCode,setCaptchaCode]= useState<string>('');
    const [captchaImg,setCaptchaImg]= useState<string>('');
    const [captchaError,setCaptchaError]= useState<string>('');
    const [count,setCount]= useState<number>(0);
    const [smsCode,setSmsCode] = useState<string>('');
    const [showPassword,setShowPassword] = useState<boolean>(false);
    const [showPasswordRepeat,setShowPasswordRepeat] = useState<boolean>(false);
    const intervalRef = useRef<any>(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // 获取单个参数值
    const from = queryParams.get('from');

    // const { from }  = useParams<any>();

    //获取用户信息
    const getInfo=async ()=>{
        //const info:any =await type===2? AgentApi.getAgentInfo():SellerApi.getSellerInfo();
        const info:any =await SellerApi.getSellerInfo();
        // debugger;
        setUsername(info.phone);
    }

    useEffect(()=>{

        // debugger;
        // // @ts-ignore
        // setFrom(fromValue);
        if(from==='change'){
            getInfo();
        }
        return () => {
            clearInterval(intervalRef.current);
        };
    },[])

    //倒计时
    useEffect(()=>{
        if(count===59){
            intervalRef.current = setInterval(() => {
                setCount((preCount) => preCount - 1);
            }, 1000);
        } else if (count === 0) {
            clearInterval(intervalRef.current);
        }
    },[count]);


    const showCaptcha=(e?:any)=>{
        if( e ){
            e.preventDefault();
        }
        //图形验证码
        SellerApi.getCaptcha({ key:username }).then((res:any)=>{
            if( res?.data?.isSuccess ){
                let img = "data:image/png;base64," + res?.data?.data?.base64;
                setStatus(1);
                setCaptchaCode('');
                setCaptchaImg(`${img}`);
            }else{
                if( status == 1 ){
                    setCaptchaError(`${res?.data?.errroMsg}`)
                }else{
                    Modal.info({content: <p>{res?.data?.errroMsg}</p>})
                }
            }
        });
    }

    const showCaptchaWindow=(e:any)=>{
        setCaptchaError('');
        showCaptcha(e);
    }

    const sendSms= ()=>{
        if(type==2){
            AgentApi.sendSMS({ mobile:username, valiCode:captchaCode }).then(res=> {
                showResult(res.data);
            })
        }else{
            SellerApi.sendSMS({ mobile:username, valiCode:captchaCode }).then(res=>{
                console.log(res)
                showResult(res.data)
            })
        }
    }

    const showResult = (data:any)=>{
        if(data?.isSuccess){
            setCaptchaError('');
            setStatus(2);
            setCount(59);
        }else{
            showCaptcha();
            setCaptchaError(`${data.errroMsg}`);
        }
    }

   const next=()=>{
        if( status === 2 ){
            AgentApi.valicode({mobile: username, valiCode:smsCode}).then((data:any)=>{
                console.log(data)
                if( data.data.data === true ){
                    setStatus(10)
                }else{
                    Modal.info({content:<p>Incorrect SMS verification code</p>});
                }
            })
        }else{
            Modal.info({content:<p>Please obtain the SMS verification code first!</p>});
        }
    }

    const submit=async ()=>{
        try {
            if( password!=passwordRepeat ){
                Modal.info({content:<p>The passwords entered twice are inconsistent.</p>});
                return;
            }
            const res= await type===2? AgentApi.forgetPassword({ mobile: username, password: password, valiCode:smsCode }):SellerApi.forgetPassword({ mobile: username, password: password, valiCode:smsCode });
            Modal.info({
                onOk:()=>{window.location.href='/'+(type===2?'agency':'seller')+'/login'},
                content: <p>Password successfully modified, click to log in again!</p>
            });
        }catch (e) {
            console.log(e)
        }
    }

        return (<div className="loginWrap">
            <h1>{ from === 'change'?'Change Password':'Forget Password'}</h1>
            <div>
                {status<10&&<><h2 style={{marginBottom:"34px"}}>Please verify your phone number first</h2>
                    <div className="loginIptWrap">
                        <Cell icon={<img alt="" src={iconMobile} style={{ width: 24, height: 24 }} />}>
                            <ZAInput
                                clearable
                                type="number"
                                placeholder="Please enter your phone number"
                                disabled={from==='change'}
                                value={username}
                                onChange={(value:any) => { setUsername(value); }}/>
                        </Cell>
                        <Cell
                            className="cellVerification"
                            icon={<img alt="" src={iconSmsCode} style={{ width: 24, height: 24 }} />}
                            description={<Button
                                type="link"
                                // disabled={ username.length!=11||count>0 }
                                disabled={ count>0 }
                                style={{alignSelf:"flex-end"}}
                                onClick={(e)=>showCaptchaWindow(e)}>
                                {count==0?(status==2?'Reacquire':'Send verification code'):(count+'s')}
                            </Button>}>
                                <ZAInput
                                    type="text"
                                    placeholder="Please enter the verification code"
                                    value={smsCode}
                                    onChange={(value:any) => { setSmsCode(value); }}/>
                        </Cell>
                    </div>
                    <ZAButton block shape="radius" disabled={smsCode==''} className="loginBtn text-normal" onClick={()=>next()}>Next Step</ZAButton>
                </>}     


                {status==10&&<><h2 style={{marginBottom:"34px"}}>Change Password </h2>
                    <div className="loginIptWrap">
                        <Cell
                            title="New Password"
                            description={<span className="v-middle" onClick={()=>setShowPassword(!setPassword)}>
                                {showPassword&&<EyeOutlined style={{fontSize:'20px'}} />}
                                {!showPassword&&<img src={eyeClose} style={{height: '24px'}} />}
                                </span>}>
                            <ZAInput
                                clearable
                                type={showPassword?"text":'password'}
                                placeholder="Please enter a new password with no less than 6 characters"
                                disabled={from==='change'}
                                value={password}
                                onChange={(value:any) => { setPassword(value); }}/>
                        </Cell>
                        <Cell
                            title="Conform Password"
                            description={ <span  className="v-middle" onClick={()=>setShowPasswordRepeat(!setPasswordRepeat)}>
                                {showPasswordRepeat&&<EyeOutlined style={{fontSize:'20px'}} />}
                                {!showPasswordRepeat&&<img src={eyeClose} style={{height: '24px'}} />}
                            </span>}>
                            <ZAInput
                                clearable
                                type={showPasswordRepeat?"text":'password'}
                                placeholder="Please confirm the password entered"
                                value={passwordRepeat}
                                onChange={(value:any) => { setPasswordRepeat(value); }}/>
                        </Cell>
                    </div>
                    <div className="block-submit box-row-between-m">
                        <ZAButton className="loginBtn text-normal"  onClick={()=>setStatus(2) }>Previous step</ZAButton>
                        <ZAButton className="loginBtn text-normal box-flex-def" disabled={password.length<6||passwordRepeat.length<6} onClick={()=>submit()}>Change Password</ZAButton>
                    </div>
                </>}
            </div>

                <Modal className="ant-modal-confirm" visible={status===1} footer={false} closable={false}>
                    <h3 className="padding-space"><br />Enter a graphic verification code</h3>
                    <div  className="padding-space">
                        <Input className="smscode-captcha" type="number" value={captchaCode} placeholder="Please enter the verification code" onChange={(e)=>setCaptchaCode(e.target.value)} />
                    </div>
                    <div className="box-row-start-m padding-space" >
                        <img style={{width:"85px",height: '44px'}} src={captchaImg} />
                        <a href="#" onClick={(e)=>showCaptcha(e)}>invisibility? change another one</a>
                    </div>

                    {captchaError&&<div className="red">{captchaError}</div>}
                    <hr />
                    <div>
                        <Button onClick={()=>setStatus(0)}>Cancel</Button>
                        <Button type="primary" onClick={()=>sendSms()}>Confirm</Button>
                    </div>
                </Modal>
            
        </div>
        );
}
export default ForgotPassword;