import React, {useEffect} from 'react'
import {  Modal } from 'antd';
import { FormOutlined, GlobalOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import Cookies from 'universal-cookie';
import { util } from '../../../utils/Util';
import SellerApi from "../../../api/Seller";
import userAvatar from '../../../assets/icon/seller-avatar.jpg';
import { useState } from 'react';
import {Input as ZAInput, Cell, Icon, Button, StackPicker, FilePicker} from 'zarm';
import Divisions from "../../../utils/DivisionsCN.json";
import axios from "axios";


const UpdateInfo = ()=>{

    const [info,setInfo]=useState<any>('');
    const [logo,setLogo] = useState<any>();
    const [logoFile,setLogoFile] = useState<any>();
    const [name,setName] = useState<string>('');
    const [realName,setRealName] = useState<string>('');
    const [area,setArea] = useState<any>('');
    const [areaId,setAreaId] = useState<string[]>([]);
    const [province,setProvince] = useState<string>('');
    const [city,setCity] = useState<string>('');
    const [address,setAddress] = useState<string>('');
    const [phone,setPhone] = useState<string>('');
    const [businessLicenseFile,setBusinessLicenseFile] = useState<string>('');

    const [binding,setBinding]=useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [value, setValue] = useState([]);
    const [Divisions, setDivisions] = useState<any>([]);

    // const getAreaText = (source:any,province:string,city:string,county:string)=>{
    //     const provinceObj:any = source.find((item:any)=>{ return item.value === province });
    //     const cityObj:any = provinceObj?.children?.find((item:any)=>{ return item.value === city });
    //     const countyObj:any = cityObj?.children?.find((item:any)=>{ return item.value === county })
    //     setArea(`${provinceObj?.label}/${cityObj?.label}/${countyObj?.label}`);
    // }

    //用户信息
    const fetchData=()=>{
        SellerApi.getSellerInfo().then((data:any)=>{
            setInfo(data);
            setName(data.name);
            setRealName(data.realName);
            setPhone(data.phone);
            setAddress(data.address);
            setProvince(data.province);
            setCity(data.city);
            // axios.get("/area.json").then(res=>{
            //     // console.log(res.data)
            //     setDivisions(res.data);
            //     getAreaText(res.data,data.province,data.city,data.county);
            //
            //     const arrayIds = [data.province,data.city,(data.county=='undefined'||!data.county)?'':data.county].filter(item=>item);
            //     let domtext:any = document.getElementsByClassName('za-input__content')[0];
            //     console.log(domtext.innerText)
            //     if(domtext.innerText.indexOf("/undefined")!=-1){
            //         domtext.innerText = `${domtext.innerText.replace('/undefined',"")}`
            //     }
            //
            //     console.log(arrayIds)
            //     setAreaId(arrayIds);
            // })
        })
    }

    useEffect(()=>{
        fetchData();
    },[])

    //保存
    const onSubmit= (e:any)=>{
        e.preventDefault();
        let data = new FormData();
        data.append('name', name);
        data.append('realName', realName);
        data.append('province', province);
        data.append('city', city);
        data.append('county', areaId[2]??"");
        data.append('address', address);
        data.append('phone', phone);
        if( logo ) data.append('logo', logo);
        if( businessLicenseFile )data.append('businessLicense', businessLicenseFile);

        SellerApi.updateSellerInfo(data).then((data)=>{
            if(data){
                Modal.info({
                    onOk: ()=>window.location.href='/seller/my',
                    content:<p>Update Successfully.</p>
                });
            }
        })
    }



    //上传头像
    const doSetFileLogo = (file:any)=> {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload =  (e:any)=> {
            setLogoFile(e.target.result);
        }
    }

    return (
        <div style={{padding:"12px",height:"100vh"}} className="userInfoWrap relative">
            <div className="center box-column-m-m ">
                <div className="seller-logo relative">
                    <img src={logoFile||info.logo||userAvatar} className="seller-logo" />
                    <span className="button-blue absolute"  style={{top:'44px',height:'20px',left:'12px'}}>Edit</span>
                    <input
                        className="absolute"
                        style={{opacity:0,width:"60px",height:"60px"}}
                        type="file"
                        accept="image/*"
                        onChange={(e)=>{
                            setLogo(e.target.files?e.target.files[0]:'');
                            doSetFileLogo(e.target.files?e.target.files[0]:'')
                        }}/>
                </div>
            </div>
            <h2 className="center" style={{margin:"10px 0 18px"}}>{info.name}</h2>

            <Cell title="Store name" style={{borderRadius:"6px 6px 0 0"}}>
                <ZAInput
                    clearable
                    type="text"
                    placeholder="Please fill in store name"
                    value={name}
                    onChange= {(value:any) => { setName(value);}} />
            </Cell>

            <Cell title="Store manager">
                <ZAInput
                    clearable
                    type="text"
                    placeholder="Please fill in  store manager "
                    value={realName}
                    onChange={(value:any) => { setRealName(value);}}/>
            </Cell>

            <Cell title="Province">
                <ZAInput
                    clearable
                    type="text"
                    placeholder=""
                    value={province}
                    onChange={(value:any) => { setProvince(value);}}/>
            </Cell>
            <Cell title="City">
                <ZAInput
                    clearable
                    type="text"
                    placeholder=""
                    value={city}
                    onChange={(value:any) => { setCity(value);}}/>
            </Cell>


            <Cell title="Address">
                <ZAInput
                    clearable
                    type="text"
                    placeholder="Street, building number, etc"
                    value={address}
                    onChange={(value:any) => { setAddress(value);}}/>
            </Cell>
            <Cell title="Phone">
                <ZAInput
                    clearable
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    onChange={(value:any) => { setPhone(value);}}/>
            </Cell>

            <Cell
                title="Business license"
                description={
                    <FilePicker accept="image/*" onChange={(e:any)=>{ setBusinessLicenseFile(e.file);}}>
                        <div style={{width:'230px',height:'50px',lineHeight:'50px'}} className={info.businessLicenseUrl?'':'text-danger'} >
                            {info.businessLicenseUrl?'Re-upload':'Uploaded'}
                            <RightOutlined style={{color:"#999"}}/>
                        </div>
                    </FilePicker>
                }>
            </Cell>

            {/*<Cell>*/}
            {/*    <Button block className="ZABtn text-normal text-bold box-flex-def" style={{color:'#ffffff'}} onClick={(e)=>{ onSubmit(e);}}>Save</Button>*/}

            {/*</Cell>*/}

            <div style={{width:"100%",padding:"0 12px",position:"absolute",bottom:"100px",left:"0"}} className="box-row-m-m">
                 <Button block className="ZABtn text-normal text-bold box-flex-def" style={{color:'#ffffff'}} onClick={(e)=>{ onSubmit(e);}}>Save</Button>

            </div>



        </div>
    );
}
export default UpdateInfo;
