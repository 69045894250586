import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { version as pdfjsVersion } from 'pdfjs-dist';
import './HelpViewer.css'; // 引入CSS文件

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

interface Params {
    url: string;
}

const HelpViewer = () => {
    const { url } = useParams<Params>();
    const zoomPluginInstance = zoomPlugin();

    return (
        <div className="pdfViewerWrap">
            <Link to="/seller/help" className="back-link">Back to Support Documents</Link>
            <div className="pdfContainer">
                <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`}>
                    <Viewer fileUrl={decodeURIComponent(url)} plugins={[zoomPluginInstance]} />
                </Worker>
            </div>
        </div>
    );
}

export default HelpViewer;
