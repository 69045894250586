import React from 'react';
import { Button, Menu, Modal, Popover, Space, Table } from 'antd';
import { Switch, BrowserRouter as Router, Route, Link, useLocation, Redirect } from "react-router-dom";
import { Row, Col } from 'antd';
import { util } from '../../utils/Util';
import Moment from 'react-moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import {LoadingOutlined} from "@ant-design/icons";
import {SegmentedControl, WingBlank} from "antd-mobile";
import Placeholder from "../components/placeholder/placeholder";
import couponNull from "../../assets/img/coupon-null.png";



export default class CouponsList extends React.Component<{}, {loadedPage:number,couponType:any, statData:any, data:Array<any>,nodata:boolean,hasMore:boolean}>{
    constructor(props:any){
        super(props);
        this.state = {
            loadedPage: 1,
            couponType:0,
            statData:'',
            data:[],
            nodata: false,
            hasMore:true,
        }
    }

    componentDidMount(){
        this.fetchData(1);
    };


    fetchData(page:any){
        let  url = process.env.REACT_APP_REMOTE_URL+"/seller/coupon/list/"+page;
        if(this.state.couponType){
            url = `${url}?type=${this.state.couponType}`
        }
        fetch(url, {headers: {'Authorization': util.getToken()}})
            .then(res=>util.handleResponse(res) )
            .then(data=>{
                    this.setState({ hasMore:data.length>=10 })
                    if(data.length){
                        this.setState({data:[...this.state.data,...data], loadedPage: page});
                    }else{
                        if( page == 1 ){
                            this.setState({nodata: true});
                        }else{
                            this.setState({loadedPage: 0});
                        }
                    }
                }
            )
            .catch(()=>{});
    }

    changeType=(e:any)=>{
        this.setState({couponType: e.nativeEvent.selectedSegmentIndex,data:[]},()=>{
            this.fetchData(1)
        });
    }
    loadMore(){
        this.fetchData(this.state.loadedPage+1);
    }

    render(){

        const { data,hasMore }= this.state;

        return (<div>
            <div className="coupons-summary">
                <div className="center">
                    <WingBlank size="lg" style={{paddingBottom:"13px"}} className="box-row-m-m">
                        <SegmentedControl
                            values={['All', 'Cash','Discount','Gift']}
                            tintColor={'#ff1e1e'}
                            style={{width: '352px', height: '36px'}}
                            selectedIndex={this.state.couponType}
                            onChange={this.changeType}
                        />
                    </WingBlank>
                </div>
            </div>

            <div className="coupons-main" id="scrollableDiv">
                {data.length>0&&<InfiniteScroll
                    scrollableTarget="scrollableDiv"
                    dataLength={data.length}
                    next={()=>this.loadMore()}
                    hasMore={hasMore}
                    loader={this.state.loadedPage!=0?<div className="center"><LoadingOutlined /></div>:''}>
                    {data.map((item)=>
                        <>
                            <div className="row-coupon">
                                <Link to={"/seller/coupon/view/"+item.id}>
                                    <Coupon data={item} key={item.id} refresh={(id:any)=>{
                                        // this.fetchData(1)
                                        let res=this.state.data?.filter((item:any)=>{return item&&item.id!=id});
                                        this.setState({
                                            data:res
                                        })
                                    }
                                    } />
                                </Link>
                            </div>
                        </>
                    )}
                </InfiniteScroll>}
                {this.state.loadedPage==0&&<div className="center light"><br />no more ...</div>}
                {data.length===0&& <div className="box-row-m-m placeholderWrap"  style={{width:"100%",height:"500px"}}>
                    <Placeholder
                        src={<img src={couponNull} alt="coupon empty logos" style={{width:"170px",height:"114px"}}/>}
                        title="Currently, there are no  coupons"
                        message="You haven't created a new coupon yet"
                        button={<Button className="btn-createActivity text-normal text-s box-row-m-m" style={{width:"130px"}}   onClick={()=>{window.location.href="/seller/coupon/create"}}>Create Coupon</Button>}></Placeholder>
                </div>}
            </div>

            {data.length>0&&<div className="bottom-tool">
                <Link to="/seller/coupon/create" className="active">New<br />Coupon</Link>
            </div>}
        </div>);
    }

}

export const Coupon = (props:{data:any, refresh?:any, viewOnly?:boolean})=>{
    const deleteCoupon = (e:any, id: any)=>{
        e.preventDefault();
        Modal.confirm({
            content: 'Confirm to delete this coupon?',
            okText: 'Confirm',
            zIndex: 10000,
            cancelText: 'Cancel',
            onOk: ()=>{
                fetch(process.env.REACT_APP_REMOTE_URL+'/seller/coupon/'+id,
                    {headers: {'Authorization': util.getToken()},
                        method:'DELETE'})
                    .then(res=>util.handleResponse(res) )
                    .then( (resp) => {
                        props.refresh(id);

                    }).catch(()=>{});
            }
        });

    };

    const isExpired = util.isCouponExpired(props.data);

    if( !props.data ){
        return null;
    }

    return <Row className={isExpired?'coupon-expired':''}>
        <Col span={8}  className="coupon-info">
            <div className={"coupon-status"+" coupon-status-"+props.data.status}>
                {!isExpired&&props.data.status==1&&<span>In use</span>}
                {isExpired&&<span>Expired</span>}
            </div>
            <div>
                <CouponSummary key={props.data.id} data={props.data} />
            </div>
        </Col>
        <Col span={16} className="coupon-body box-content">
            {!props.viewOnly&&
            <div className="right" style={{lineHeight:'20px'}}>
                <Popover content={<div>
                    <p><Link to={"/seller/coupon/record/"+props.data.id}>coupon records</Link></p>
                    <hr/>
                    <p><a onClick={(e)=>deleteCoupon(e, props.data.id)}>delete</a></p>
                </div>} placement="bottom" trigger="click">
                    <Button size="small" type="link">...</Button>
                </Popover>
            </div>
            }
            <h3>{props.data.name}</h3>
            <div style={{marginTop:'6px'}} className="text-xs">
                <label className="text-xs">Valid: </label>
                {props.data.timeType==1&&<span ><span style={{fontSize:'10px',color:'#333333'}}><Moment format="YYYY.MM.DD" >{props.data.dateFrom}</Moment></span>~<span style={{fontSize:'10px',color:'#333333'}}><Moment format="YYYY.MM.DD">{props.data.dateTo}</Moment></span></span>}
                {props.data.timeType==2&&<><span className="text-def text-xs">is valid after receiving in  {props.data.validDates.validDates} days</span></>}
            </div>
            <Row className="coupon-stat text-xs">
                <Col span={12}><label style={{fontSize:'12px'}}>total: </label><span className="text-def text-xs">{props.data.totalCount+props.data.statSend}</span></Col>
                <Col span={12}><label style={{fontSize:'12px'}}>received: </label><span className="text-def text-xs">{props.data.statSend}</span></Col>
            </Row>
            <Row style={{marginTop:'3px'}} className="text-xs">
                <Col span={12}><label style={{fontSize:'12px'}}>Remaining: </label><span className="text-def text-xs">{props.data.totalCount}</span></Col>
                <Col span={12}><label style={{fontSize:'12px'}}>Redeemed: </label><span className="text-def text-xs">{props.data.statUsed}</span></Col>
            </Row>
        </Col>
    </Row>;
}

export const CouponSummary=(props:{data:any, oneLine?:boolean, prefix?:boolean})=>{
    const prefix = ()=>{
        if( props.prefix) {
            let text = '';
            if( props.data.type==1 ){
                text = 'cash';
            }else if( props.data.type==2 ){
                text = 'discount';
            }else if( props.data.type==3 ){
                text = 'gift';
            }
            return <span className="coupon-type">[{text}]</span>;
        }else{
            return '';
        }

    }

    if( props.data.type==1 ){
        let discount = props.data.discount/100;
        let least = props.data.least/100;
        if( props.oneLine ){
            return <>{prefix()}
            <span>Minus {discount}  from {least}  </span>

            </>
        }
        return <>
            <h3><span></span>{discount}</h3>
            <span className="coupon-note">Available at {least} or more </span>

        </>;
    }else if( props.data.type==2 ){
        if( props.oneLine ){
            return <>{prefix()}<span style={{"fontSize":"1.5em"}}>{props.data.discountRate}% off</span></>
        }
        return  <><h3>{props.data.discountRate}%</h3><span style={{"fontSize":"1.5em"}}>off</span> </>;
    }else if( props.data.type==3 ){
        if( props.oneLine ){
            return <>{prefix()}<span>{props.data.giftName}</span></>
        }
        return <h3 className="giftname">{props.data.giftName}</h3>;
    }
    return <></>;
}