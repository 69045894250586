import {Button, Card, Col, Form, Input, Modal, Radio, Row, Space} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { DateTime, util } from '../../utils/Util';
import iconBack from '../../assets/icon/icon-back.png'

export class CreateCoupon extends React.Component<{}, {type:string, timeType:string, saving:boolean, dateFrom:any, dateTo:any,receiveType:string,description:any,validDates:number,defaultEndTime?:Date,defaultStartTime?:Date}>{
    formRef: any;

    constructor(props:any){
        super(props);
        this.formRef = React.createRef();
        this.state = {
            type:"3",
            timeType: "2",
            saving:false,
            dateFrom:'',
            dateTo:'',
            receiveType:'3',
            description:'',
            validDates:30,
            defaultEndTime:undefined,
            defaultStartTime:new Date()
        };
    }
    componentDidMount() {
        this.getDesc();
        if(!this.state.dateTo){
            const date = moment().add(180,'days').toDate();
            const defaltDateText = moment().format('YYYY-MM-DD');
            this.setState({
                defaultEndTime:date,
                dateFrom:defaltDateText,
                dateTo:moment(date).format('YYYY-MM-DD')
            },()=>{
                this.getDesc()
            })
        }

    }

    getDesc = ()=>{
        const { dateFrom,dateTo,timeType,validDates} = this.state;
        const startTime = timeType==='1'&&dateFrom?`Available time：${dateFrom}`:'';
        const endTime = timeType==='1'&&dateTo?dateTo:'';

        const exp="Exclusion time: general for legal holidays";
        let result = '';
        if(timeType==='1'){        //类型1
            if(startTime&&endTime){ // 开始结束时间不为空
                const timeText = `${startTime}~${endTime}`
                result = [timeText,exp].join('\n')
            }
        }else{
            const days = validDates?`is valid after receiving in ${validDates} days`:'';
            result = [days,exp].join('\n')
        }
        this.setState({ description: result.trim() });
        this.formRef.current.setFieldsValue({description:result})
    }

    submit(values:any) {
        if(this.state.timeType=="1" ){
            if( !this.state.dateFrom ){
                Modal.error({content:<p>Please enter a start time</p>});
                return;
            }else{
                const fromDateAddition = this.state.dateFrom.split(' ').length==2? this.state.dateFrom: `${this.state.dateFrom} 00:00:00`;
                values['dateFrom'] =fromDateAddition;
            }

            if( !this.state.dateTo ){
                Modal.error({content:<p>Please enter the end time</p>});
                return;
            }else{
                const toDateAddition = this.state.dateTo.split(' ').length==2? this.state.dateTo: `${this.state.dateTo} 23:59:59`;
                values['dateTo'] = toDateAddition;
            }
        }
        this.setState({saving: true});
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/coupon/saveOrUpdate",{
            method:'POST',
            headers: {
                'Authorization': util.getToken(),
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(values)
        }).then(res=>util.handleResponse(res) )
        .then((data:any)=>{
            if( data ){
                Modal.info({
                    onOk: ()=>window.location.href="/seller/coupons",
                    content:<p>Coupon Created!</p>
                });
            }
        })
        .catch(()=>{})
        .finally(()=>this.setState({saving: false}));
    }

    changeType(e:any){
        this.setState({type:e.target.value},()=>{
            this.getDesc();
        });
        this.formRef.current.setFieldsValue({description:''})
    }
    changeReceiveType(e:any){
        this.setState({receiveType:e.target.value});
    }

    changeTimeType(e:any){
        this.setState({timeType:e.target.value},()=>{
            this.setState({validDates:30},()=>{
                this.getDesc();
            })
        });
    }

    validateFailed( errorInfo:any){
        //验证required
        let message = '';
        for( let info of errorInfo.errorFields ){
            message += info.errors.join(';') + '<br />';
        }
        util.message( message );
    }

    render(){

        return (<div className="relative createCardWrap">
            <Card className='createCouponContent'>
            <Form
                ref={this.formRef}
                style={{backgroundColor:'white'}}
                size="large"
                requiredMark={false}
                validateTrigger="onSubmit"
                onFinishFailed={(errorInfo:any)=>this.validateFailed(errorInfo)}
                onFinish={(values)=>this.submit(values)}>
                <Form.Item label="Coupon Name" name="name" rules={[{required:true, message:'Enter coupon name'}]}>
                    <Input placeholder="Enter coupon name" />
                </Form.Item>

                <Form.Item label="Coupon Type" name="type" initialValue="3">
                    <Radio.Group onChange={(e)=>this.changeType(e)}>
                        <Radio value="1">cash</Radio>
                        <Radio value="2">discount</Radio>
                        <Radio value="3">gift</Radio>
                    </Radio.Group>
                </Form.Item>

                {this.state.type=="1"&&<Row className="box-row-start-m">
                    <Col>
                            Over
                            <Form.Item name="least" rules={[{required: true, message:'Please enter how much can be subtracted when full'}]} className="inline small">
                            <Input type="number" placeholder="amount of money"  />
                        </Form.Item>
                        <span className="unit"></span>
                            Off
                            <Form.Item name="discount" rules={[{required: true, message:'Please enter how much to subtract'}]} className="inline small">
                            <Input type="number"  placeholder="amount of money" />
                        </Form.Item>
                        {/*<span className="unit">元</span>*/}
                        {/*<div className="tip">If no minimum price, fill in 0 </div>*/}
                    </Col>
                </Row>}

                {this.state.type=="2"&&<Row>
                    <Col>
                        Enter discount
                            <Form.Item name="discountRate" rules={[{required: true, message:'Please enter the discount'}]} className="inline small">
                            <Input type="number" max={100} min={0} />
                        </Form.Item>
                        <span className="unit">%</span>
                        <div className="tip">For example, 30 is a 30% discount, 5.5 is a 5.5% discount.</div>
                    </Col>
                </Row>}

                 {this.state.type=="3"&&<Row>
                    <Col>
                        Enter a gift name
                            <Form.Item name="giftName" rules={[{required: true, message:'Please enter a gift name'},{max: 20, message: 'Gift name is within 6 characters'}]} className="inline medium">
                            <Input />
                        </Form.Item>
                        <div className="tip">For example, Coke</div>
                    </Col>
                </Row>}

                <Form.Item label="Issued quantity" name="totalCount" rules={[{required: true, message:'Please input issued quantity'}]} initialValue="10000">
                    <Input type="number" placeholder="Fill in the expected distribution quantity until it is collected completely" />
                </Form.Item>

                <Form.Item label="Redemption Limit " name="receiveType" rules={[{required: true, message:'Please select a limited collection type'}]} initialValue="3">
                    {/*<Input type="number" placeholder="单个手机号码限制领取数量, 防止被刷券" />*/}
                    <Radio.Group onChange={(e)=>this.changeReceiveType(e)}>
                        <Radio value="1">Total amount redeemable</Radio>
                        <Radio value="2">Amount redeemed per day</Radio>
                        <Radio value="3">Unlimited</Radio>
                    </Radio.Group>
                </Form.Item>

                {this.state.receiveType=='1'&&<Row>
                    <Col>
                        <Form.Item name="personCount" rules={[{required: this.state.receiveType=='1', message:'Please enter the limited collection quantity per person'}]} className="inline small" initialValue="1">
                            <Input type="number" placeholder="Please enter the limited collection quantity per person" />
                        </Form.Item>
                        <span className="unit"></span>
                    </Col>
                </Row>}
                {this.state.receiveType=='2'&&<Row>
                    <Col>
                        <Form.Item name="personCount" rules={[{required: this.state.receiveType=='2', message:'Please enter the daily limit quantity'}]} className="inline small" initialValue="1">
                            <Input type="number" placeholder="Daily collectable" />
                        </Form.Item>
                        <span className="unit"></span>
                    </Col>
                </Row>}

                <Form.Item label="Coupon Valid Period" name="timeType" initialValue="2">
                    <Radio.Group onChange={(e)=>{
                        this.changeTimeType(e)
                    }} >
                        <Radio value="2">Coupon is valid for (XX days)</Radio>
                        <Radio value="1">Coupon is valid between</Radio>
                    </Radio.Group>
                </Form.Item>

                {this.state.timeType=="1"&&<div>
                    <DateTime placeholder="start time"  value={this.state.defaultStartTime} dateonly={true} onSelect={(value:any)=>{
                        if(!this.state.dateTo){
                            const date = moment(value,'YYYY-MM-DD').add(180,'days').toDate();
                            this.setState({
                                defaultEndTime:date,
                            })
                        }
                        this.setState({dateFrom: value},()=>{
                            this.getDesc();
                        });
                      }} />
                    TO
                    <DateTime placeholder="end time" value={this.state.defaultEndTime}  dateonly={true}  onSelect={(value:any)=>{
                        this.setState({dateTo: value},()=>{
                            this.getDesc();
                        });
                    }} />

                </div>}

                {this.state.timeType=="2"&&<Row className="box-row-start-m">
                    <Col>
                        is valid after receiving<Form.Item className="inline small" name="validDates" initialValue={30} >
                            <Input type="number" onChange={(e:any)=>{
                                this.setState({validDates:e.target.value},()=>{
                                    this.getDesc();
                                })
                            }
                            }/>
                        </Form.Item><span className="unit" style={{marginLeft:"20px"}}>days</span>
                    </Col>
                </Row>}

                <Form.Item label="Instructions" name="description">
                    <TextArea placeholder="please input coupon usage instructions" rows={6} />
                </Form.Item>

                <div className="box-row-between-m fixed createCouponBottom" style={{width:"100%",bottom:"0",left:"0"}}>
                        <Link to="/seller/coupons">
                            <div  className='createCouponLeft' >
                                <img src={iconBack}/>
                                <span>Previous step</span>
                            </div>
                        </Link>
                        <Button htmlType="submit" className='gradient' style={{borderRadius:0}} disabled={this.state.saving} size="large" type="primary">Create Coupon</Button>
                </div>
            </Form>
            </Card>
        </div>);
    }

}