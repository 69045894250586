import { CloseCircleOutlined, LinkedinOutlined, LinkOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, {useEffect, useState} from "react";
//@ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { util } from "../../utils/Util";
import {SegmentedControl, WingBlank} from "antd-mobile";
import { useHistory } from "react-router";

const ActivityPoster=(props:{posterData?:any,id: any, onClose?:any})=>{
   const [tab, setTab] = useState<number>(0);
   const [posterData,setPosterData] = useState<any>({});

   const history = useHistory();
   
   useEffect(()=>{
       if(props.id){
           fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getActById?id="+props.id,
               {headers: {'Authorization': util.getToken()}})
               .then(res=>util.handleResponse(res))
               .then(data=>{
                   setPosterData(data?.posterInfo);
               }).catch(()=>{});
       }
       //兼容ios返回失效问题
       window.addEventListener('pageshow', function(e) {
           //如果检测到页面是从“往返缓存”中读取的，刷新页面
           if (e.persisted) {
               window.location.reload();
           }
       });
   },[props.id])

    const getUrl = ()=>{
       return posterData?.qrCodeUrl;

    }

    const onCopy = ()=>{
       util.message("Link copied");
    }

    return <div className="poster-container relative box-column-start-m">
                <div className="poster box-flex-def box-column-m-m" >
                    <img src={ posterData?.qrCodeUrl } width={194} height={194} />

                    <CloseCircleOutlined style={{fontSize:'36px', marginTop:'12px'}} onClick={()=>{ history.go(-1) }} />
                </div>


                <div className="poster-buttons bottomActionFixed">
                    <CopyToClipboard text={getUrl()} onCopy={()=>onCopy()}>
                        <Button type="link" className="text-s"><LinkOutlined />Copy Download Link </Button>
                    </CopyToClipboard>
                </div>
    </div>;}

export default  ActivityPoster;