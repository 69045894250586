import React from 'react';
import { Link } from "react-router-dom";
import {Activity} from '../Activities';
import { ActivityStat } from './ViewActivity';
import { util } from '../../../utils/Util';
import SellerApi  from '../../../api/Seller';
import arrowRightWhiteIcon from "../../../assets/svg/arrow_right_white.svg";
import timerIcon from "../../../assets/icon/icon-timer.jpg";
import xiangdouIcon from "../../../assets/icon/icon-xiangdou.jpg";
import userAvatar from '../../../assets/icon/seller-avatar.jpg';
import activityNull from '../../../assets/img/activity-null.png';
import { RightOutlined } from '@ant-design/icons';
import "./index.scss";
import Placeholder from "../../components/placeholder/placeholder";


export default class Default extends React.Component<{}, {data:any, activity:any}>{       
    constructor(props:any){
        super(props);
        this.state = {data:'', activity:''}
    }

    componentDidMount(){
        let token = util.getToken()
        if( !token ){
            window.location.href='/seller/login';
            return;
        }

        if( util.getLocationParam('from') == 'weixin' ){
            window.location.href='/seller?v='+new Date().getTime();
            return;
        }

        this.fetchData();
    };


    async fetchData(){
        try{
            const data:any=await SellerApi.getSellerInfo();
            if(data){
                this.setState({data:data});
                if( data.sellerType == 2 ){
                    window.location.href = '/seller/subsidary?v='+new Date().getTime();//若为母公司则跳转
                }
            }
            const activity:any=await SellerApi.getCurrentActivity();
            if(activity){
                this.setState({activity:activity})
            }else{
                const activityList:any=await SellerApi.getActivityList({pageNo:1});
                if( activityList.length > 0 ){
                    this.setState({activity:activityList[0]});
                }else{
                    this.setState({activity:false});
                }
            }
        }catch (err){
            console.log(err);
        }
    }


    render(){
        let info = this.state.data;
        if( !info ){
            return '';
        }

        return (
            <div className="seller-default relative">
                {info.platform=='douyin'&&!info.douyinId}
                <div className="seller-header relative" >
                    <div className="box-row-start-m" style={{height:"80px"}}>
                        {info.logo&&<div className="seller-logo" style={{backgroundImage:"url('"+info.logo+"') "}}></div>}
                        {!info.logo&&<div className="seller-logo" style={{backgroundImage:"url('"+userAvatar+"') "}}></div>}
                        <div className="box-column-m-start">
                            <h2 className="text-def text-l">{info.name}</h2>
                            <div className="box-row-start-m timer-info-box">
                                <img src={timerIcon} alt="timer-icon" className="timerIcon"/>
                                <div className="text-xs text-def2">Valid until:&nbsp; {info.expired?info.expired.split(' ')[0]:''}</div>
                            </div>
                            <Link to="/seller/points" className="box-row-start-m xiangdou-info-box" style={{"width":"150px"}}>
                                <img src={xiangdouIcon} alt="xiangdouIcon" className="icon-xiangdou"/>
                                <div className="text-normal text-xs box-flex-def">Video Points: &nbsp; {info.points<1?'0':info.points}</div>
                                <img src={arrowRightWhiteIcon} alt="arrowRightWhiteIcon" />
                            </Link>
                        </div>
                    </div>
                    <ActivityStat />
                </div>

                <div className="main-block my-activities">
                    <div className="box-row-between-base activity-title-wrap">
                        <h2>
                            <span className="h2-strip"></span>
                            My Campaigns
                        </h2>
                        <span>
                            <Link to="/seller/activities" className="text-xs text-def3">...More</Link>
                            <RightOutlined style={{color:"#999"}}/>
                        </span>
                    </div>
                    {this.state.activity&&<div>
                        <Activity data={this.state.activity} />
                    </div>}

                    {this.state.activity===false&&<div className="box-row-m-m box-bg-def placeholderWrap"  style={{width:"100%",height:"290px"}}>
                        <Placeholder
                            src={<img src={activityNull} alt="Activity is empty logo" style={{width:"149px",height:"114px"}}/>}
                            title="Currently no campaign"
                            message="You haven't created a new campaign yet"
                            button={<div></div>}></Placeholder>
                    </div>}
                </div>
            </div>
        );
    }
}

