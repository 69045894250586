import React from  'react';
import './mine.scss'

interface  Props{
    icon:React.ReactNode,
    title:React.ReactNode
    content:React.ReactNode
}

const XMListItem = (props:Props)=>{
        return(
            <div className='listWrap box-row-between-m'>
                <div className='iconWrap'>
                    {props.icon}
                </div>
                <div className='box-flex-def text-s text-def'>
                    {props.title}
                </div>
                <div className="text-xs">
                    {props.content}
                </div>
            </div>
        )
}

export  default  XMListItem;