import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Activity } from '../Activities';
import { Coupon, CouponSummary } from '../Coupons';
import { CalcVideoCount, Format, util } from '../../../utils/Util';
import activityDataInfoLogo from "../../../assets/icon/activityDataInfoLogo.png";


export class ViewActivity extends React.Component<{id:string}, {data:any, couponData:any, maxVideoCount:any}>{       
    constructor(props:any){
        super(props);
        this.state = {data:'', couponData:'', maxVideoCount:''}
    }

    componentDidMount(){
        this.fetchData();
      };
  
      fetchData(){
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getActById?id="+this.props.id, 
        {headers:{Authorization: util.getToken()}})
        .then(res=>util.handleResponse(res))
        .then(data=>{
            this.setState({data:data});
            if( data.couponIdList ){
                this.fetchCoupon(data.couponIdList);
            }

        }).catch(()=>{});                               
      }


    fetchCoupon(id:string){
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/coupon/"+id, 
        {headers:{Authorization: util.getToken()}})
        .then(res=>util.handleResponse(res))
        .then(data=> this.setState({couponData:data}))
        .catch(()=>{}); 
    }

    render(){       
        let data = this.state.data;    
        if( !data ){
            return '';
        }

        return (
            <div className="view space-container">
                <div>
                    <ActivityStatRender data={data} />
                    <br />
                </div>

                <div className="box linespace">
                    <h3 className='box-row-start-m'>

                        {data.actName}
                    </h3>
                    <div>{data.actDescription}</div>
                    <div>
                        <label>Activity rewards : </label>
                        {this.state.couponData&&<CouponSummary prefix={true} oneLine={true} data={this.state.couponData}  />}
                    </div>
                    <div>
                        <label>Activity time: </label>
                        {<span>{data.dateFrom} ~ {data.dateTo}</span>}
                    </div>
                </div>                


                <div className="box">
                    <h2>Coupon configuration </h2>
                    <div className="coupon-white">
                        <Coupon data={this.state.couponData} viewOnly={true} />
                    </div>
                </div>
            </div>
        );
    }

}


export const ActivityStat = (props:any)=>{

    const [data, setData] = useState('' as any);

    useEffect(() => {
        fetch(process.env.REACT_APP_REMOTE_URL+"/seller/getActDataSummary",
            {headers: {'Authorization': util.getToken()}})
            .then(res=>util.handleResponse(res))
            .then(data=>{setData(data);
            }).catch(()=>{});
    }, []);              


    return <ActivityStatRender data={data} status={1}/>
}


export const ActivityStatRender = (props:{data:any, hideHeader?:boolean,status?:1}) =>{
    const data = props.data;
    const status = props.status;

    return (
        <div className={`${status==1?'activityDataBoxActive':'activityDataBox'}`}>
            {!props.hideHeader&&<div className="box-row-between-m" style={{height:"32px",marginTop:"-6px"}}>
                <div className='box-row-start-m'>
                    <img src={activityDataInfoLogo} alt="Activity Data logo" className="activityDataLogo" />
                    <div className={`actTitle text-s text-bold ${status==1?'text-normal':'text-def'}`} >Campaign Data</div>
                </div>
                <div className={`text-xs ${status == 1 ? 'actTipsActive' : 'actTips'}` } >updated once a day</div>
            </div>}
            <div className="block-stat">
                <div className="box-row-between-m">
                    <div className="box-column-m-m text-xs">
                        <div className={`${status != 1 ? 'text-def3' : ''}`}>Scanned</div>
                        <div className="text-xxl text-bold" style={{margin:"4px 0"}}><Format number={data.statScan} /></div>
                        {/*<div className={`${status != 1 ? 'text-danger' : ''}`}>today{data.statScanDailyInc?<span>+<Format number={data.statScanDailyInc} /></span>:' 0'}</div>*/}
                    </div>
                    <div className="box-column-m-m text-xs">
                        <div className={`${status != 1 ? 'text-def3' : ''}`}>coupon sent</div>
                        <div className="text-xxl text-bold" style={{margin:"4px 0"}}><Format number={data.statCard} /></div>
                        {/*<div className={`${status != 1 ? 'text-danger' : ''}`}>today{data.statCardDailyInc?<span>+<Format number={data.statCardDailyInc} /></span>:' 0'}</div>*/}
                    </div>
                    <div className="box-column-m-m text-xs">
                        <div className={`${status != 1 ? 'text-def3' : ''}`}>Redeemed</div>
                        <div className="text-xxl text-bold" style={{margin:"4px 0"}}><Format number={data.statWriteoff} /></div>
                        {/*<div className={`${status != 1 ? 'text-danger' : ''}`}>today{data.statWriteoffDailyInc?<span>+<Format number={data.statWriteoffDailyInc} /></span>:' 0'}</div>*/}
                    </div>
                </div>
                <div className="box-row-between-m" style={{marginTop:"18px"}}>
                    <div className="box-column-m-m text-xs">
                        <div className={`${status != 1 ? 'text-def3' : ''}`}>Videos</div>
                        <div className="text-xxl text-bold" style={{margin:"4px 0 0"}}><Format number={data.statVideo} /></div>
                    </div>
                    <div className="box-column-m-m text-xs">
                        <div className={`${status != 1 ? 'text-def3' : ''}`}>Views</div>
                        <div className="text-xxl text-bold" style={{margin:"4px 0 0"}}><Format number={data.statPlay} /></div>
                    </div>
                    <div className="box-column-m-m text-xs">
                        <div className={`${status != 1 ? 'text-def3' : ''}`}>Likes</div>
                        <div className="text-xxl text-bold" style={{margin:"4px 0 0"}}><Format number={data.statLike} /></div>
                    </div>
                </div>
            </div>
        </div>
    );

}